import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

function SingleLineChart({ data }) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <span className="label">{`${data[0].name} : ${payload[0].value}`}</span>
        </div>
      );
    }
    return null;
  };
  return (
    <ResponsiveContainer height={210} width="100%">
      <LineChart data={data} margin={{ right: 20, top: 20 }}>
        <XAxis
          dataKey="date"
          interval={0}
          domain={[0, (dataMax) => (dataMax >= 50 ? dataMax : 50)]}
          style={{ fontSize: '12px' }}
        />
        <YAxis
          tickCount={5}
          interval={0}
          domain={[0, (dataMax) => (dataMax >= 50 ? dataMax : 50)]}
          style={{ fontSize: '12px' }}
        />
        <Line type="monotone" dataKey="count" stroke="#8884d8" dot={false} />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default SingleLineChart;
