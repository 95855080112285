export default ({ width = '30px', height = '30px', fill = '#FFFFFF' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={"white"}
        stroke="none"
      >
        <path
          d="M2511 5106 c-43 -24 -50 -52 -51 -193 0 -145 8 -176 51 -199 52 -27
        115 -8 137 41 7 16 12 76 12 155 0 79 -5 139 -12 155 -22 48 -88 68 -137 41z"
        />
        <path
          d="M1583 4858 c-40 -10 -73 -51 -73 -90 0 -34 121 -251 153 -273 37 -26
        94 -19 128 14 45 45 39 76 -37 207 -36 62 -71 117 -77 123 -25 20 -62 28 -94
        19z"
        />
        <path
          d="M3468 4852 c-24 -13 -49 -45 -100 -132 -79 -134 -84 -165 -39 -211
        34 -33 91 -40 128 -14 30 21 144 223 150 266 10 70 -72 124 -139 91z"
        />
        <path
          d="M2385 4489 c-164 -22 -367 -87 -424 -135 -16 -13 -32 -35 -35 -49
        -10 -39 18 -92 58 -112 35 -16 37 -16 133 20 111 42 190 63 281 75 l62 9 0
        -1499 0 -1498 -143 0 -143 0 -12 72 c-26 149 -119 375 -234 566 -28 48 -133
        209 -233 357 -189 282 -236 371 -275 525 -66 255 -52 480 47 743 l37 99 -19
        36 c-26 48 -80 70 -121 50 -38 -18 -67 -63 -103 -163 -115 -318 -104 -684 29
        -1001 48 -114 84 -173 305 -501 228 -338 354 -595 370 -756 6 -50 5 -53 -26
        -72 -63 -39 -74 -77 -77 -287 -4 -217 3 -253 58 -308 l38 -38 4 -199 c3 -176
        5 -203 24 -244 31 -66 78 -114 142 -146 l57 -28 375 0 375 0 57 28 c64 32 111
        80 142 146 19 41 21 68 24 244 l4 199 38 38 c21 21 43 56 49 77 15 54 14 375
        -1 427 -8 25 -30 58 -57 84 -41 40 -43 45 -38 85 8 57 51 192 87 272 24 53 36
        68 70 85 121 62 210 222 210 378 0 47 1 48 46 70 97 47 188 158 219 266 26 88
        18 223 -18 299 l-26 58 53 54 c208 212 190 551 -43 785 l-74 74 2 61 c2 72
        -20 161 -55 221 -76 129 -238 224 -384 224 -44 0 -47 2 -59 38 -45 124 -176
        217 -365 258 -86 19 -335 26 -431 13z m389 -210 c33 -6 82 -21 110 -33 53 -23
        116 -85 116 -112 0 -9 -22 -36 -50 -61 -57 -51 -120 -167 -120 -220 0 -50 45
        -93 97 -93 52 0 71 15 108 91 38 78 88 117 161 125 157 19 299 -108 280 -250
        -22 -171 -24 -165 71 -220 121 -71 202 -223 190 -356 -10 -109 -61 -189 -162
        -255 -65 -42 -77 -57 -78 -99 -1 -25 10 -50 40 -93 50 -72 63 -111 63 -182 -1
        -137 -94 -222 -272 -246 -53 -8 -141 14 -180 44 -14 12 -41 59 -63 113 -30 73
        -51 105 -95 150 -52 54 -59 58 -101 58 -61 0 -99 -37 -99 -96 0 -33 7 -46 40
        -79 27 -27 51 -67 77 -128 66 -159 162 -235 331 -259 92 -14 91 -13 67 -94
        -16 -54 -70 -115 -118 -133 -37 -14 -103 -14 -130 0 -12 7 -41 29 -64 50 -56
        51 -109 54 -154 10 -37 -37 -38 -83 -4 -133 28 -41 130 -113 176 -123 23 -5
        29 -11 23 -23 -27 -66 -67 -199 -77 -260 l-12 -72 -142 0 -143 0 0 820 0 820
        45 0 c115 0 248 54 318 129 24 26 69 89 100 141 31 53 74 109 95 127 23 19 42
        44 45 60 14 75 -58 136 -131 112 -51 -17 -130 -101 -177 -189 -22 -41 -56 -91
        -76 -111 -41 -41 -112 -69 -176 -69 l-43 0 0 579 0 578 28 -4 c15 -2 54 -9 86
        -14z m286 -3329 l0 -150 -500 0 -500 0 0 150 0 150 500 0 500 0 0 -150z m-100
        -510 c0 -174 -7 -203 -51 -226 -41 -21 -657 -21 -698 0 -44 23 -51 52 -51 226
        l0 160 400 0 400 0 0 -160z"
        />
        <path
          d="M855 4148 c-37 -33 -47 -79 -27 -120 13 -24 45 -49 132 -100 134 -79
        165 -84 211 -39 33 34 40 91 14 128 -23 33 -237 153 -273 153 -18 0 -42 -10
        -57 -22z"
        />
        <path
          d="M4065 4105 c-60 -35 -118 -74 -127 -86 -29 -35 -23 -95 11 -130 46
        -45 77 -40 211 39 87 51 119 76 132 100 20 41 10 87 -27 120 -42 36 -78 28
        -200 -43z"
        />
        <path
          d="M1586 4094 c-20 -21 -26 -37 -26 -69 0 -59 36 -95 95 -95 32 0 48 6
        69 26 20 21 26 37 26 69 0 59 -36 95 -95 95 -32 0 -48 -6 -69 -26z"
        />
        <path
          d="M611 3206 c-47 -26 -63 -84 -37 -135 23 -43 54 -51 196 -51 142 0
        173 8 196 51 27 52 8 115 -41 137 -37 17 -282 15 -314 -2z"
        />
        <path
          d="M4191 3206 c-47 -26 -63 -84 -37 -135 23 -43 54 -51 196 -51 142 0
        173 8 196 51 27 52 8 115 -41 137 -37 17 -282 15 -314 -2z"
        />
        <path
          d="M960 2312 c-129 -75 -156 -107 -139 -165 11 -42 53 -77 90 -77 35 0
        251 121 274 153 26 37 19 94 -14 128 -45 45 -77 39 -211 -39z"
        />
        <path
          d="M3949 2351 c-33 -34 -40 -91 -14 -128 23 -32 239 -153 274 -153 37 0
        79 35 90 77 17 58 -10 89 -139 165 -134 79 -165 84 -211 39z"
        />
      </g>
    </svg>
  );
};
