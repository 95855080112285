import config from '../config/config';
import { general } from './General';

const uploadImage = async (file) => {
  try {
    const compressedImage = await general.compressImage(file, {
      quality: 0.5,
      type: 'image/jpeg',
    });

    const formData = new FormData();
    formData.append('uploaded_file', compressedImage);

    const user = general.getUser();

    const response = await fetch(
      `${config.QUEST_PROTOCOL_URL}/api/upload-img`,
      {
        method: 'POST',
        body: formData,
        headers: {
          apiKey: config.API_KEY,
          apiSecret: config.API_SECRET,
          userId: user.userId,
          token: user.token,
        },
      }
    );

    const data = await response.json();

    if (data.success === false) {
      throw new Error(data.error);
    }

    return config.IPFS_PREFIX + data.data.IpfsHash;
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  uploadImage,
};
