export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2490 4399 c-116 -16 -207 -65 -290 -155 -260 -285 -97 -742 288
-806 258 -43 513 147 553 414 20 138 -24 284 -119 389 -109 121 -271 180 -432
158z m202 -197 c105 -52 170 -150 176 -267 7 -125 -52 -231 -159 -290 -44 -23
-69 -30 -135 -33 -71 -4 -89 -1 -136 20 -115 53 -178 142 -186 262 -2 39 1 89
7 112 24 89 104 173 199 209 57 22 176 15 234 -13z"
        />
        <path
          d="M3314 3791 c-76 -46 -112 -142 -85 -224 27 -80 134 -139 215 -118 41
10 41 10 94 -42 142 -138 243 -357 259 -559 l6 -78 -135 0 -135 0 -13 63 c-38
175 -171 347 -331 425 -144 71 -151 72 -624 72 -473 0 -480 -1 -625 -72 -163
-80 -303 -255 -339 -425 l-14 -63 -236 0 c-134 0 -252 -5 -274 -11 -49 -13
-105 -63 -128 -114 -36 -79 -30 -101 107 -377 69 -139 137 -268 151 -286 46
-61 90 -75 251 -80 136 -4 142 -5 143 -26 0 -11 19 -267 42 -568 37 -477 45
-549 61 -567 39 -43 113 -35 140 16 13 26 9 97 -36 687 -27 362 -47 665 -44
672 8 21 1584 21 1592 0 3 -8 -17 -308 -44 -668 -47 -619 -49 -655 -33 -688
29 -62 118 -62 147 0 7 14 28 248 48 520 20 272 39 523 43 556 l6 62 141 4
c158 5 203 20 249 80 46 61 277 537 277 571 0 118 -96 217 -212 217 -24 0 -27
4 -32 48 -24 199 -51 300 -116 426 -51 100 -112 184 -188 262 -58 59 -60 64
-55 102 10 75 -37 161 -104 188 -48 20 -132 18 -169 -5z m-338 -642 c90 -16
178 -63 244 -129 58 -58 114 -154 126 -217 l7 -33 -793 0 -792 0 6 33 c12 62
66 154 123 211 63 62 168 121 243 135 67 13 765 13 836 0z m1031 -576 c4 -10
-37 -102 -113 -252 l-119 -236 -120 -3 -120 -2 -2 40 c-5 80 -62 152 -142 179
-24 8 -262 11 -831 11 -569 0 -807 -3 -831 -11 -80 -27 -139 -101 -142 -180
l-1 -40 -121 3 -120 3 -121 240 c-67 132 -119 245 -117 252 4 10 290 13 1449
13 1339 0 1445 -1 1451 -17z"
        />
      </g>
    </svg>
  );
};
