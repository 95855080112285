import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { importConfig } from "../../../config/importConfig";
import { HashLink } from "react-router-hash-link";

const Navbar = ({openPopup, setOpenPopup}) => {
    const navigate = useNavigate();

    const animate1 = {
        hidden: { y: -50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
            },
        },
    };

    return (
        <div className="fixed w-full top-0 left-0 " style={{ zIndex: 1 }}>
            <motion.div
                className="px-5 pt-8 flex items-center justify-between lg:px-[60px] lg:pt-12 relative"
                variants={animate1}
                initial="hidden"
                animate="visible"
            >
                <img
                    src={importConfig.brandLogo}
                    alt=""
                    className="w-12 mr-24"
                    variants={animate1}
                />
                <div
                    className={`flex ${
                        openPopup
                            ? "opacity-100 scale-100"
                            : "opacity-0 scale-0"
                    } top-[100px] w-[calc(100%-40px)] lg:opacity-100 lg:scale-100 rounded-[32px] items-center lg:w-fit left- flex-col duration-200 ease-in gap-6 px-8 py-10 fixed bg-home-2 lg:rounded-full lg:flex-row lg:gap-8 lg:py-4 lg:static border-[1px] `}
                    style={{ backdropFilter: "blur(10px)" }}
                    id="disabledClick"
                >
                    <HashLink
                        to="#introduction"
                        className="text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        Introduction
                    </HashLink>
                    <HashLink
                        to="#how-it-works"
                        className="text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        How It Works
                    </HashLink>
                    <HashLink
                        to="#key-features"
                        className="text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        Key Features
                    </HashLink>
                    <HashLink
                        to="#faqs"
                        className="text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        FAQs
                    </HashLink>
                    <Link
                        to="pricing"
                        className="text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        Pricing
                    </Link>
                    {/* <Link
                        to="https://calendly.com/shubham-quest/chat"
                        target="_id"
                        className="flex lg:hidden text-home-3 duration-500 font-semibold cursor-pointer text-xl lg:text-base"
                    >
                        Book Call
                    </Link> */}
                </div>
                <button
                    className="opacity-0 cursor-default hidden lg:flex rounded-md duration-300 px-7 py-3 text-home-4 bg-black font-bold"
                    style={{ backdropFilter: "blur(10px)" }}
                    variants={animate1}
                    // onClick={() => window.open("https://calendly.com/shubham-quest/chat", "_blank")}
                >
                    Book Call
                </button>
                <button
                    className="flex lg:hidden rounded-md duration-300  px-3 py-3  text-home-4 bg-black font-bold"
                    style={{ backdropFilter: "blur(10px)" }}
                    onClick={() => setOpenPopup(!openPopup)}
                    id="openPopupButton"
                >
                    <img
                        src={importConfig.home.menuBar}
                        alt=""
                        className="w-8"
                    />
                </button>
            </motion.div>
        </div>
    );
};

export default Navbar;
