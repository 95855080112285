import React, { useRef } from "react";
import { motion } from "framer-motion";
import { importConfig } from "../../../config/importConfig";
import Typewriter from 'typewriter-effect';
import {
    animation1,
    animation10,
    animation2,
    animation3,
    animation4,
    animation5,
    animation6,
    animation7,
    animation8,
    animation9,
} from "./HeadersAnimation";
import { useNavigate } from "react-router";
let userData = JSON.parse(localStorage.getItem("user"))

const Header = ({ collectEmailFunction }) => {
    const emailRef = useRef("")
    const navigate = useNavigate()

    return (
        <div>
            <motion.div className="pt-[57px] xl:pt-[120px] px-5 xl:px-[60px] w-full duration-200">
                <motion.div
                    className="text-[36px] xl:text-[80px] text-home-2 w-full text-center h-36 xl:h-60"
                    variants={animation1}
                    initial="hidden"
                    animate="visible"
                >
                    {/* <p className="m-auto text-center -mt-5">Idea to MVP</p>
                    <p className="m-auto text-center -mt-6">in 2 weeks</p> */}
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.typeString('<strong class="text-home-1 font-light">Build a Buzz with <br/><span class="heading-text-bg font-bold">Viral WaitList</span></strong>')
                                .pauseFor(1000)
                                .deleteAll()
                                .start();
                        }}
                        options={{
                            autoStart: true,
                            loop: true,
                            delay: 60,
                        }}
                    />
                </motion.div>
                <motion.div
                    className="text-[18px] xl:text-[22px] text-home-6 w-full text-center h-20 md:h-28"
                    variants={animation1}
                    initial="hidden"
                    animate="visible"
                >
                    <p className="m-auto text-center -mt-2">Get Sign-Ups and Go Viral</p>
                    <p className="m-auto text-center -mt-1">Even Before You Launch</p>
                </motion.div>
                <motion.div
                    className="mt-3 w-full xl:w-[465px] m-auto flex justify-center gap-4"
                    variants={animation2}
                    initial="hidden"
                    animate="visible"
                >
                    <button
                        className="px-7 py-3 w-[145px] text-home-4 bg-black rounded-md"
                        style={{
                            // background:"linear-gradient(90deg,#240037,#9405e4)",
                            // boxShadow: "0px 20px 35px rgba(221, 255, 0, 0.2)",
                        }}
                        onClick={() => { !!userData?.userId ? navigate("/create-waitlist") : navigate("login") }}
                    >
                        Get Started
                    </button>
                    {/* <button
                        className="px-7 py-3 w-[145px] text-home-1 border border-black bg-transparent  rounded-md"
                        style={{
                            // background:"linear-gradient(90deg,#240037,#9405e4)",
                            // boxShadow: "0px 20px 35px rgba(221, 255, 0, 0.2)",
                        }}
                        onClick={() => window.open("https://calendly.com/shubham-quest/chat", "_blank")}
                    >
                        Let's Talk
                    </button> */}
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Header;
