import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClickAway } from 'react-use';

import styles from '../styles/Sidebar.module.css';
import config from '../config/config';
import axios from 'axios';
import * as svgs from '../assets/svgs';
import * as reactSvgs from '../assets/reactSvgs';
import * as images from '../assets/images';
import { general } from '../helpers/General';

export default function Sidebar() {
  const [accountDropDownVisible, setAccountDropDownVisible] = useState(false);
  const [projectDropDownVisible, setProjectDropDownVisible] = useState(false);
  const [waitlists, setWaitlists] = useState([]);
  const [currentProject, setCurrentProject] = useState({ title: 'Waitlists' });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [expandNav, setExpandNav] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = general.getUser();
  const project = general.getProject();
  const accountDropDownRef = useRef();
  const projectDropDownRef = useRef();

  const waitlistId = searchParams.get('waitlistId');

  useEffect(() => {
    let path = window.location.pathname
    if (path.includes("/overview")) {
      setSelectedRoute("overview")
    } else if (path.includes("/submissions")) {
      setSelectedRoute("submissions")
    } else if (path.includes("/account")) {
      setSelectedRoute("account")
    } else if (path.includes("/upgrade")) {
      setSelectedRoute("upgrade")
    } 
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (user.projectId?.length > 0) {
      axios
        .get(`${config.BACKEND_URL}api/viralwaitlist/waitlists`, {
          headers: {
            userId: user.userId,
            token: user.token,
          },
          params: {
            projectId: user.projectId,
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            throw new Error(res.data.error);
          }
          setWaitlists(
            res.data.data.map((project) => {
              return {
                title: project.title,
                questId: project.questId ? project.questId : '',
              };
            })
          );
          handleCurrentProject(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (waitlistId && waitlists.length > 0) {
      handleCurrentProject(waitlists);
    }
  }, [waitlistId]);

  useEffect(() => {
    if (windowWidth <= 800 && expandNav) {
      setExpandNav(false);
    } else if (windowWidth > 800 && expandNav === false) {
      setExpandNav(true);
    }
  }, [windowWidth]);

  const handleCurrentProject = (projects) => {
    if (!waitlistId) {
      setCurrentProject({
        ...currentProject,
        waitlistId: projects[0]?.questId,
      });
    } else {
      let matchedindex = 0;

      for (let index in projects) {
        let project = projects[index];
        if (project?.questId === waitlistId) {
          matchedindex = index;
        }
      }
      setCurrentProject({
        title: projects[matchedindex].title,
        waitlistId: projects[matchedindex].questId,
      });
    }
  };

  const handleAccountClick = () => {
    setAccountDropDownVisible((prev) => !prev);
  };

  const handleProjectDropDownClick = () => {
    general.fireTrackingEvent('waitlist_dropdown_selector', 'sidebar');
    setProjectDropDownVisible((prev) => !prev);
  };

  const handleLogout = () => {
    general.logout();
    navigate('/login');
  };

  const handlePageClick = (page) => {
    if (
      !user.projectId ||
      !user.projectId.length === 0 ||
      waitlists.length == 0
    )
      return;

    general.fireTrackingEvent(`page_button: ${page}`, 'sidebar');
    switch (page) {
      case 'Overview': {
        navigate(`/overview?waitlistId=${currentProject.waitlistId}`);
        break;
      }
      case 'Submissions': {
        navigate(`/submissions?waitlistId=${currentProject.waitlistId}`);
        break;
      }
      default: {
        navigate('/');
      }
    }
  };

  const toggleHamburger = () => {
    setExpandNav((prev) => !prev);
  };

  useClickAway(accountDropDownRef, (event) => {
    if (event.target.innerText !== 'My Account') {
      handleAccountClick();
    }
  });

  useClickAway(projectDropDownRef, (event) => {
    if (
      !!event?.target?.className?.length && event.target.className !== '' &&
      !event.target.className?.includes('dropdown-main')
    ) {
      handleProjectDropDownClick();
    }
  });

  return (
    <div className={styles.main}>
      <div>
      <div className={styles['header-container']}>
        <div className={styles['header-container-inner']}>
          <Link to="/account">
            <img
              src={
                project.image.length > 0
                  ? project.image
                  : svgs.viralWaitlistLogo
              }
              alt="viral-waitlist-logo"
            />
          </Link>
          <p
            title={project.name.length > 0 ? project.name : 'My Account'}
            onClick={handleAccountClick}
          >
            {project.name.length > 0 ? project.name : 'My Account'}
          </p>
        </div>
        <div className={styles['svg-container']}>
          <reactSvgs.downArrowIcon />
        </div>
        <div className={styles['mobile-header-container']}>
          <p
            className={styles['mobile-header']}
            title={project.name.length > 0 ? project.name : 'My Account'}
            onClick={handleAccountClick}
          >
            {project.name.length > 0 ? project.name : 'My Account'}
          </p>
          <reactSvgs.downArrowIcon />
        </div>
        <div
          className={styles['hamburger-container']}
          onClick={toggleHamburger}
        >
          <img src={images.hamburgerIcon} alt="" />
        </div>
        {/* {accountDropDownVisible && (
          <div
            className={styles['account-dropdown']}
            onClick={handleLogout}
            ref={accountDropDownRef}
          >
            <p>Log Out</p>
          </div>
        )} */}
      </div>
      {expandNav && (
        <>
          <div className={styles['manage-container']}>
            <div
              className={styles['project-dropdown-main']}
              onClick={handleProjectDropDownClick}
            >
              <div className={styles['project-name-container']}>
                <img src={svgs.viralWaitlistLogo} alt="viral-waitlist-logo" />
                <p title={currentProject.title}>{currentProject.title}</p>
              </div>
              <reactSvgs.unfoldIcon style={{ width: '20px' }} />
            </div>
            {projectDropDownVisible && (
              <div
                className={styles['project-dropdown']}
                ref={projectDropDownRef}
              >
                <Link
                  to="/create-waitlist"
                  className={styles['project-dropdown-name-container']}
                >
                  <p>New project +</p>
                </Link>
                {waitlists.map((waitlist, index) => (
                  <Link
                    to={`/overview?waitlistId=${waitlist.questId}`}
                    className={styles['project-dropdown-name-container']}
                    key={index}
                  >
                    <p>{waitlist.title}</p>
                  </Link>
                ))}
              </div>
            )}
            <p className={styles['title']}>MANAGE YOUR WAITLIST</p>
            <div className={styles['page-container']}>
              <div
                className={`${styles['page-name-container']} ${selectedRoute == "overview" && styles['selected-route']}`}
                onClick={() => handlePageClick('Overview')}
              >
                <reactSvgs.barGraph style={{ width: '20px' }} />
                <p>Overview</p>
              </div>
              <div
                className={`${styles['page-name-container']} ${selectedRoute == "submissions" && styles['selected-route']}`}
                onClick={() => handlePageClick('Submissions')}
              >
                <reactSvgs.foldersIcon style={{ width: '20px' }} />
                <p>Submissions</p>
              </div>
              <Link
                className={`${styles['page-name-container']} ${selectedRoute == "upgrade" && styles['selected-route']}`}
                to="/upgrade"
                onClick={() => {
                  general.fireTrackingEvent('page_button: upgrade', 'sidebar');
                }}
              >
                <reactSvgs.pencilIcon style={{ width: '20px' }} />
                <p>Upgrade</p>
              </Link>
              <Link
                className={`${styles['page-name-container']} ${selectedRoute == "account" && styles['selected-route']}`}
                to="/account"
                onClick={() => {
                  general.fireTrackingEvent('page_button: account', 'sidebar');
                }}
              >
                <reactSvgs.userIcon width="18px" height="18px" />
                <p>Account</p>
              </Link>
            </div>
          </div>
          <div className={styles['resource-container-main']}>
            <p className={styles['title']}>HELP AND RESOURCES</p>
            <div className={styles['resource-container']}>
              <Link
                to="mailto:shubham@questapp.xyz?subject=Feedback%20on%20Viral%20Waitlist&cc=deb@questapp.xyz"
                className={styles['resource-title-container']}
                onClick={() => {
                  general.fireTrackingEvent('feedback_button', 'sidebar');
                }}
              >
                <reactSvgs.smileyFaceIcon />
                <p>Feedback</p>
              </Link>
              <Link
                to="mailto:shubham@questapp.xyz?cc=deb@questapp.xyz"
                className={styles['resource-title-container']}
                onClick={() => {
                  general.fireTrackingEvent('contact_button', 'sidebar');
                }}
              >
                <reactSvgs.messageIcon />
                <p>Contact us</p>
              </Link>
            </div>
          </div>
        </>
      )}
      </div>
      { expandNav &&
        <div className='p-[15px] flex items-center gap-2 border-t text-black cursor-pointer' style={{borderColor: "silver"}} onClick={handleLogout}>
          <reactSvgs.logout width='18px' height='18px'/>
          <p className="font-bold">Log out</p>
        </div>
      }
    </div>
  );
}
