export default function () {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.075 7.5C27.1125 7.9375 26.075 8.225 25 8.3625C26.1 7.7 26.95 6.65 27.35 5.3875C26.3125 6.0125 25.1625 6.45 23.95 6.7C22.9625 5.625 21.575 5 20 5C17.0625 5 14.6625 7.4 14.6625 10.3625C14.6625 10.7875 14.7125 11.2 14.8 11.5875C10.35 11.3625 6.38755 9.225 3.75005 5.9875C3.28755 6.775 3.02505 7.7 3.02505 8.675C3.02505 10.5375 3.96255 12.1875 5.41255 13.125C4.52505 13.125 3.70005 12.875 2.97505 12.5V12.5375C2.97505 15.1375 4.82505 17.3125 7.27505 17.8C6.48846 18.0153 5.66267 18.0452 4.86255 17.8875C5.20206 18.9531 5.86697 19.8855 6.76382 20.5537C7.66067 21.2218 8.74436 21.5921 9.86255 21.6125C7.96709 23.113 5.61754 23.9241 3.20005 23.9125C2.77505 23.9125 2.35005 23.8875 1.92505 23.8375C4.30005 25.3625 7.12505 26.25 10.15 26.25C20 26.25 25.4125 18.075 25.4125 10.9875C25.4125 10.75 25.4125 10.525 25.4 10.2875C26.45 9.5375 27.35 8.5875 28.075 7.5Z"
        fill="#333333"
      />
    </svg>
  );
}
