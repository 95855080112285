import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";

function Projects() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isUnfoldClicked, setIsUnfoldClicked] = useState(false);
  const handleButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsUnfoldClicked(false);
  };
  const handleUnfoldClick = () => {
    setIsUnfoldClicked(!isUnfoldClicked);
    setIsPopupOpen(false);
  };
  useEffect(() => {
    // state 
    // map
    // axios.get
  }, []);
  return (
    <div style={{ background: "rgb(243 244 246)", display: "flex" }}>
      <Sidebar />
      <div
        style={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          paddingLeft: " 3rem",
          paddingRight: "3rem",
          maxWidth: "64rem",
          alignContent: "center",
          width: "100vh",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "1.5rem",
              lineHeight: "2rem",
              paddingBottom: "0.25rem",
            }}
          >
            Your Projects (1)
          </h1>
          <div
            style={{
              marginTop: "1.5rem",
              gap: "1.5rem",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
            }}
          >
            <div
              style={{
                textAlign: "center",
                border: "1px solid rgb(209 213 219)",
                padding: "1rem",
                borderWidth: "1px",
                borderRadius: "1.5rem",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  margin: "0px",
                }}
              >
                0
              </p>
              <h3
                style={{
                  color: "rgb(55 65 81)",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  margin: "0px",
                }}
              >
                Users in waitlists
              </h3>
            </div>
            <div
              style={{
                textAlign: "center",
                border: "1px solid rgb(209 213 219)",
                padding: "1rem",
                borderWidth: "1px",
                borderRadius: "1.5rem",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  margin: "0px",
                }}
              >
                0
              </p>
              <h3
                style={{
                  color: "rgb(55 65 81)",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  margin: "0px",
                }}
              >
                Joined via referrals
              </h3>
            </div>
            <div
              style={{
                textAlign: "center",
                border: "1px solid rgb(209 213 219)",
                padding: "1rem",
                borderWidth: "1px",
                borderRadius: "1.5rem",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  margin: "0px",
                }}
              >
                0
              </p>
              <h3
                style={{
                  color: "rgb(55 65 81)",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  margin: "0px",
                }}
              >
                Verified emails
              </h3>
            </div>
            <div
              style={{
                textAlign: "center",
                border: "1px solid rgb(209 213 219)",
                padding: "1rem",
                borderWidth: "1px",
                borderRadius: "1.5rem",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  margin: "0px",
                }}
              >
                0
              </p>
              <h3
                style={{
                  color: "rgb(55 65 81)",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  margin: "0px",
                }}
              >
                Joins today
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
