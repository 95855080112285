export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2494 5111 c-97 -25 -196 -108 -236 -198 -11 -25 -22 -71 -25 -102
-5 -50 -2 -61 19 -85 50 -58 148 -22 148 55 0 71 67 152 135 165 143 26 253
-125 182 -251 -25 -44 -85 -80 -150 -90 -66 -10 -71 -25 -77 -203 l-5 -157
-215 -73 c-197 -67 -250 -80 -640 -158 -234 -47 -436 -89 -450 -95 -14 -5
-239 -146 -500 -312 -318 -203 -479 -311 -489 -329 -13 -22 -13 -33 -4 -57 19
-52 584 -934 619 -969 57 -55 80 -46 223 87 l91 83 0 -1182 0 -1182 29 -29 29
-29 1386 0 c994 0 1392 3 1409 11 14 6 30 21 36 33 8 15 12 349 14 1189 l2
1167 105 -85 c58 -47 115 -88 127 -91 57 -15 66 -3 385 452 170 241 311 450
315 463 3 12 -1 36 -8 52 -10 19 -163 137 -484 370 -258 188 -483 348 -500
355 -16 7 -214 50 -440 94 -373 74 -431 88 -637 156 l-228 76 0 103 0 104 54
24 c69 32 131 94 165 166 22 47 26 70 26 141 0 71 -4 94 -26 141 -52 111 -165
187 -287 194 -37 2 -81 1 -98 -4z m286 -1090 l205 -68 -3 -34 c-4 -53 -57
-151 -112 -207 -155 -157 -417 -167 -575 -21 -26 24 -60 62 -75 85 -31 44 -75
162 -65 173 7 6 393 139 410 140 6 1 103 -30 215 -68z m-781 -176 c25 -101
112 -233 196 -298 116 -89 233 -129 375 -129 273 0 498 170 575 435 14 50 19
56 38 52 12 -3 178 -37 370 -75 l347 -69 425 -308 c233 -169 424 -312 425
-317 0 -6 -448 -655 -486 -705 -2 -2 -63 46 -137 107 -74 60 -143 115 -155
121 -34 18 -62 13 -93 -18 l-29 -29 0 -1221 0 -1221 -1280 0 -1280 0 0 1230
c0 892 -3 1236 -11 1254 -14 30 -59 51 -93 42 -13 -3 -86 -62 -161 -131 -76
-68 -141 -122 -145 -120 -4 3 -117 176 -250 386 -202 317 -240 383 -229 393 8
7 199 130 425 274 l410 260 370 75 c203 41 372 71 375 68 4 -3 12 -28 18 -56z"
        />
      </g>
    </svg>
  );
};
