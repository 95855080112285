import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import tierDetails from '../config/tierDetails.json';
import * as reactSvgs from '../assets/reactSvgs';
import * as image from '../assets/images';
import { general } from '../helpers/General';
import styles from '../styles/PricingPage.module.css';

function PricingPage() {
  let navigate = useNavigate();
  const [plansDuration, setPlansDuration] = useState('monthly')

  return (
    <div className={styles.main}>
      <div
        className='px-4 py-2 rounded-md mx-8 mt-4 w-fit text-black cursor-pointer border border-black flex items-center gap-1'
        onClick={() => navigate("/")}
      >
        <img src={image.backbtn} alt="" className='w-4' />
        <p>Home</p>
      </div>

      <ul className={styles['planLife']}>
        <li>
          <button
            className={`${plansDuration === 'monthly' ? styles['selButton'] : ""}`}
            onClick={() => setPlansDuration('monthly')}
          >Monthly</button>
        </li>
        <li>
          <button
            className={`${plansDuration === 'yearly' ? styles['selButton'] : ""}`} onClick={() => setPlansDuration('yearly')}
          >Yearly</button></li>
        <li>
          <button
            className={`${plansDuration === 'life' ? styles['selButton'] : ""}`} onClick={() => setPlansDuration('life')}
          >Lifetime</button></li>
      </ul>

      <div className={styles['plans']}>
        {
          (
            <div className={styles['all-plans-container']}>
              {
                Object.keys(tierDetails).map((tier, index) => (
                  <div key={index} className={styles['plan-container']}>

                    <h2>{tierDetails[tier].name}</h2>
                    <div style={{position: "relative", width: "fit-content"}}>
                      <h1>
                        {
                          typeof tierDetails[tier].price === 'number' && <span>$</span>
                        }{' '}
                        {
                          plansDuration === 'yearly' ?
                            (
                              isNaN(tierDetails[tier].price) ? tierDetails[tier].price : tierDetails[tier].yearPrice
                            ) : (
                              plansDuration === 'monthly' ?
                                (tierDetails[tier].price)
                                :
                                (
                                  tierDetails[tier].lifetime
                                )
                            )
                        }
                        {
                          (index !== 3) ? <p style={{
                            fontSize: '16px',
                            display: 'inline-block',
                            marginLeft: '3px'
                          }}>
                            {
                              plansDuration == 'monthly' ? "/mo" : plansDuration == "yearly" ? "/yr" : ""
                            }
                          </p> : ""
                        }
                      </h1>
                      {
                        plansDuration === "life" && ((index == 1 || index == 2)) &&
                        <span style={{fontSize: "12px", border: "1px solid gray", color: "gray", padding: "2px", borderRadius: "5px", position: "absolute", width: "72px", top: "25px", right: "-80px"}}>Limited time</span>
                      }
                    </div>
                    <p>
                      {
                        // (index !== 3) ? general.makeTierDescriptionNumberBold(
                          tierDetails[tier].description
                        // ) : ""
                      }
                    </p>
                    <div className={styles['buy-button-container']}>
                      <Link to="/upgrade">
                        <button>{tierDetails[tier].name} plan</button>
                      </Link>
                      {/* <p>You'll be charged once for lifetime access</p> */}
                    </div>
                    <h4>{tierDetails[tier].featureCaption}</h4>

                    <div className={styles['features-container']}>
                      {tierDetails[tier].features.map((feature, featureIndex) => (
                        <div
                          key={featureIndex}
                          className={styles['feature-container']}
                        >
                          <reactSvgs.checkIconCircle fill="black" />
                          <p>{feature}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      <div className={styles['footer-container']}>
        <Footer />
      </div>
    </div >
  );
}

export default PricingPage;
