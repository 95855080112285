import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import config from '../config/config';
import Sidebar from './Sidebar';
import Crisp from '../sdks/Crisp';
import WaitlistTemplate from '../components/WaitlistTemplate';
import * as reactSvgs from '../assets/reactSvgs';
import * as svgs from '../assets/svgs';
import { general } from '../helpers/General';

import styles from '../styles/CreateWaitlistPage.module.css';

const defaultActions = [
  {
    type: 'USER_INPUT_TEXT',
    data: { title: "What's your name?", xp: 0, frequency: 'ONCE' },
  },
  {
    type: 'USER_INPUT_TEXT',
    data: { title: "What's your email?", xp: 0, frequency: 'ONCE' },
  },
];

function CreateQuestPage() {
  const [actions, setActions] = useState(defaultActions);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState({});
  const [eligibilityCriteriaIds, setEligibilityCriteriaIds] = useState([]);
  const [eligibilityCriteriaIdsToDelete, setEligibilityCriteriaIdsToDelete] =
    useState([]);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const waitlistId = searchParams.get('waitlistId');
  const user = general.getUser();
  const project = general.getProject();

  useEffect(() => {
    if (!user.projectId || user.projectId.length === 0) {
      navigate('/account');
    }

    if (project.name.length > 0) {
      setName(project.name);
      setDescription(project.description);
    }

    Crisp.load();
    general.fireTrackingEvent("loaded", "create_waitlist_page");
  }, []);

  useEffect(() => {
    if (waitlistId) {
      (async () => {
        try {
          setGeneralLoading({
            ...generalLoading,
            waitlist: true,
          });

          const response = await general.makeRequest({
            url: `/waitlists/${waitlistId}`,
            method: 'GET',
            query: {
              userId: user.userId,
              projectId: user.projectId,
              questId: waitlistId,
            },
          });

          if (response.success === false) {
            throw new Error(response.error);
          }

          setName(response.data.title);
          setDescription(response.data?.description || '');

          const eligibilityCriteriaIds = response?.eligibilityData.map(
            (action) => {
              return {
                criteriaId: action.data.criteriaId,
                title: action.data.metadata.title,
              };
            }
          );

          setEligibilityCriteriaIds([...eligibilityCriteriaIds]);

          const actions = response?.eligibilityData.map((action) => {
            return {
              type: 'USER_INPUT_TEXT',
              data: {
                title: action.data.metadata.title,
                xp: 0,
                frequency: 'ONCE',
                id: action.data.criteriaId,
              },
            };
          });
          setActions([...actions]);
        } catch (error) {
          toast.error(error.message);
          console.log(error);
        } finally {
          delete generalLoading.waitlist;
          setGeneralLoading({ ...generalLoading });
        }
      })();
    } else {
      setName('');
      setDescription('');
      setActions([...defaultActions]);
    }
  }, [waitlistId]);

  const handleAddAction = () => {
    setActions((prevActions) => [
      ...prevActions,
      {
        type: 'USER_INPUT_TEXT',
        data: { title: '', xp: 0, frequency: 'ONCE' },
      },
    ]);
  };

  const handleRemoveAction = (indexToRemove) => {
    if (eligibilityCriteriaIds.length > 0) {
      setEligibilityCriteriaIdsToDelete((prev) => [
        ...prev,
        eligibilityCriteriaIds[indexToRemove].criteriaId,
      ]);
    }

    setActions((prevActions) =>
      prevActions.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleChangeAction = (index, value) => {
    const updatedActions = [...actions];
    updatedActions[index].data.title = value;
    setActions(updatedActions);
  };

  const handleSubmit = async (event) => {
    general.fireTrackingEvent('create_list_button', 'create_waitlist');
    try {
      event.preventDefault();
      setLoading(true);

      if (verifyQuestions() === false) {
        throw new Error(
          "Questions can't start with numbers or special characters"
        );
      }

      const payload = {
        title: name,
        description,
        eligibilityCriterias: actions,
        projectId: user.projectId,
        hasReferral: true,
        ...(eligibilityCriteriaIdsToDelete.length > 0 && {
          eligibilityCriteriaIdsToDelete,
        }),
      };

      const response = await general.makeRequest({
        url: waitlistId ? `/waitlists/${waitlistId}/update` : '/waitlists',
        method: 'POST',
        query: {
          userId: user.userId,
        },
        body: payload,
      });

      if (!response.success === true) {
        throw new Error(response.error);
      }

      toast.success(
        `waitlist ${waitlistId ? 'updated' : 'created'}  successfully`
      );
      navigate(`/overview?waitlistId=${waitlistId ? waitlistId : response.id}`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getTemplate = (template) => {
    if (Object.keys(template).length > 0) {
      setName(template.title);
      const actions = template.questions.map((question) => {
        return {
          type: 'USER_INPUT_TEXT',
          data: { title: question, xp: 0, frequency: 'ONCE' },
        };
      });
      setActions([...defaultActions, ...actions]);
      setDescription(template.description);
    } else {
      setName('');
      setDescription('');
      setActions([...defaultActions]);
    }
  };

  const verifyQuestions = () => {
    for (let action of actions) {
      if (general.isValidName(action.data.title) === false) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles['form-container']}>
            <h1>
              {waitlistId ? 'Update' : 'Create'} Waitlist &#10141; Let get some
              early users!
            </h1>
            <form
              style={{ marginTop: '1rem' }}
              className={styles.form}
              onSubmit={handleSubmit}
            >
              <div className={styles['input-container']}>
                <p>Product/Service Name</p>
                <input
                  type="text"
                  placeholder="Enter product/service name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required={true}
                />
              </div>
              <div className={styles['input-container']}>
                <p>Product/Service Description</p>
                <input
                  type="text"
                  placeholder="Enter product/service description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  required={true}
                />
              </div>
              <h4 className={styles['action-title']}>
                Following questions will be asked to the users
              </h4>
              {actions.map((action, index) => (
                <div key={index} className={styles['action-container']}>
                  <p className={styles['action-title']}>Question {index + 1}</p>
                  <div className={styles['action-input-container']}>
                    <input
                      type="text"
                      placeholder="Enter action name"
                      required={true}
                      value={action.data.title}
                      onChange={(event) =>
                        handleChangeAction(index, event.target.value)
                      }
                    />
                    {index > 0 && (
                      <button
                        className={styles['delete-btn']}
                        onClick={() => handleRemoveAction(index)}
                        type="button"
                      >
                        <img src={svgs.deleteIcon} alt="delete-icon" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div className={styles['form-button-container']}>
                <button
                  type="button"
                  onClick={handleAddAction}
                  className={styles['add-btn']}
                >
                  Add Question
                </button>
                <button type="submit" className={styles['submit-btn']}>
                  {waitlistId ? 'Update' : 'Create'} List
                </button>
              </div>
            </form>
          </div>
        </div>
        <WaitlistTemplate
          getTemplate={getTemplate}
          projectId={user.projectId}
          user={user}
        />
        {loading && (
          <div className={styles.overlay}>
            <div className={styles['loading-container']}>
              <h1>{waitlistId ? 'Updating' : 'Setting'} up your dashboard</h1>
              <reactSvgs.loadingIconGear />
            </div>
          </div>
        )}
        {generalLoading.waitlist && (
          <div className={styles.overlay}>
            <div className={styles['loading-container']}>
              <h1>Loading waitlist</h1>
              <reactSvgs.loadingIcon width="100px" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateQuestPage;
