import React, { useEffect, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import config from '../config/config';
import styles from '../styles/Referral.module.css';
import * as reactSvgs from '../assets/reactSvgs';
import { general } from '../helpers/General';

function Referral() {
  const [userDetail, setUserDetail] = useState({});
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const user = general.getUser();

  const projectId = searchParams.get('projectId');
  const waitlistId = searchParams.get('waitlistId');
  let waitlistUrl = `${window.location.origin}/invite?waitlistId=${waitlistId}&projectId=${projectId}`;

  const [shortURL, setShortURL] = useState(waitlistUrl);

  // Embed variables
  const isEmbed = window.location.pathname.includes('embed');

  let backgroundColor = '';
  let foregroundColor = '';
  let headingColor = '';
  let fontColor = '';
  let inputBackground = '';
  let inputOutline = '';
  let inputFontColor = '';
  let buttonBackground = '';

  if (isEmbed) {
    backgroundColor = searchParams.get('backgroundColor');
    foregroundColor = searchParams.get('foregroundColor');
    headingColor = searchParams.get('headingColor');
    fontColor = searchParams.get('fontColor');
    inputBackground = searchParams.get('inputBackground');
    inputOutline = searchParams.get('inputOutline');
    inputFontColor = searchParams.get('inputFontColor');
    buttonBackground = searchParams.get('buttonBackground');
  }

  useEffect(() => {
    general.fireTrackingEvent('loaded', 'referral_page');
    (async () => {
      try {
        const response = await axios.get(
          `${config.BACKEND_URL}api/viralwaitlist/waitlists/submissions`,
          {
            headers: {
              token: user.token,
              userId: user.userId,
            },
            params: {
              userId: user.userId,
              projectId,
              waitlistId,
            },
          }
        );

        if (response.data.success === false) {
          throw new Error(response.data.error);
        }

        let newWaitlistUrl = waitlistUrl;

        for (let index in response.data.data) {
          let submission = response.data.data[index];
          if (submission.userId === user.userId) {
            setUserDetail({ ...submission, position: Number(index) + 1 });
            newWaitlistUrl += `&referralCode=${submission.referralCode}`;
            break;
          }
        }

        const shortUrl = await shortenURL(newWaitlistUrl);
        setShortURL(shortUrl);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    })();
  }, []);

  const shortenURL = async (url) => {
    try {
      const response = await axios.get(
        `https://api.shrtco.de/v2/shorten?url=${encodeURIComponent(url)}`
      );

      if (response?.data?.result?.full_short_link) {
        return response.data.result.full_short_link;
      }

      return url;
    } catch (error) {
      console.log(error);
      return url;
    }
  };

  const toggleCheckIcon = () => {
    general.fireTrackingEvent(`share_waitlist_button: copy`, 'referral_page');
    navigator.clipboard.writeText(shortURL);
    setShowCheckIcon(true);
    setTimeout(() => setShowCheckIcon(false), 500);
  };

  const embedStyles = {
    main: {
      backgroundColor: backgroundColor || '',
    },
    'form-container-main': {
      backgroundColor: foregroundColor || '',
    },
    heading: {
      background: headingColor || '',
      WebkitBackgroundClip: 'text',
    },
    'action-title': {
      color: fontColor || '',
    },
    'action-input': {
      backgroundColor: inputBackground || '',
      outlineColor: inputOutline || '',
      color: inputFontColor || '',
    },
    'submit-button': {
      background: buttonBackground || '',
    },
  };

  const handleShareClick = (name) => {
    general.fireTrackingEvent(
      `share_waitlist_button: ${name}`,
      'referral_page'
    );
  };

  return (
    <div className={styles.main} style={embedStyles.main}>
      <div
        className={styles['form-container-main']}
        style={embedStyles['form-container-main']}
      >
        {loading ? (
          <>
            <h1 className={styles.heading}>Loading...</h1>
            <div className={styles['loading-container']}>
              <reactSvgs.loadingIcon />
            </div>
          </>
        ) : (
          <>
            <h1 className={styles.heading} style={embedStyles.heading}>
              You're on the waitlist!
            </h1>
            <p className={styles.caption} style={embedStyles['action-title']}>
              Your current position is <span>{userDetail.position}</span>
            </p>
            <hr className={styles.line} />
            <h3>Want to cut the line and get early access?</h3>
            <p className={styles.caption} style={embedStyles['action-title']}>
              Refer your friends and move up the list
            </p>
            <div
              className={styles['share-link-container']}
              style={embedStyles['action-input']}
            >
              <p style={embedStyles['action-title']}>{shortURL}</p>
              <button onClick={toggleCheckIcon}>
                {showCheckIcon ? (
                  <reactSvgs.checkIcon />
                ) : (
                  <reactSvgs.copyIcon />
                )}
              </button>
            </div>
            <p className={styles.caption} style={embedStyles['action-title']}>
              Your have referred <span>{userDetail.usersReferred}</span> friends
            </p>
            <p
              className={styles['share-caption']}
              style={embedStyles['action-title']}
            >
              Share on social media
            </p>
            <div className={styles['social-share-container']}>
              <div className={styles['share-icon']}>
                <FacebookShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('facebook');
                  }}
                >
                  <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
              </div>
              <div className={styles['share-icon']}>
                <WhatsappShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('whatsapp');
                  }}
                >
                  <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
              </div>
              <div className={styles['share-icon']}>
                <LinkedinShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('linkedin');
                  }}
                >
                  <LinkedinIcon size={30} round={true} />
                </LinkedinShareButton>
              </div>
              <div className={styles['share-icon']}>
                <TwitterShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('twitter');
                  }}
                >
                  <TwitterIcon size={30} round={true} />
                </TwitterShareButton>
              </div>
              <div className={styles['share-icon']}>
                <TelegramShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('telegram');
                  }}
                >
                  <TelegramIcon size={30} round={true} />
                </TelegramShareButton>
              </div>
              <div className={styles['share-icon']}>
                <EmailShareButton
                  url={shortURL}
                  onClick={() => {
                    handleShareClick('email');
                  }}
                >
                  <EmailIcon size={30} round={true} />
                </EmailShareButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Referral;
