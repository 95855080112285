import React, { useEffect } from 'react';
import {Canny} from "react-canny"

const BoardToken = '12d51a07-d6d1-344f-214d-619e044c8895';

const CannyWidget = () => {
  useEffect(() => {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          e.type = 'text/javascript';
          e.async = true;
          e.src = 'https://canny.io/sdk.js';
          f.parentNode.insertBefore(e, f);
        }
      }
      if (typeof w.Canny !== 'function') {
        var c = function () {
          c.q.push(arguments);
        };
        c.q = [];
        w.Canny = c;
        if (d.readyState === 'complete') l();
        else if (w.attachEvent) w.attachEvent('onload', l);
        else w.addEventListener('load', l, false);
      }
    })(window, document, 'canny-jssdk', 'script');

    Canny('render', {
      boardToken: BoardToken,
      basePath: null, // See step 2
      ssoToken: null, // See step 3,
      theme: 'light', // options: light [default], dark, auto
    });
  }, []);

  return <div data-canny />;
};

export default CannyWidget;
