export default function () {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7213 5.53746C25.0302 5.40745 25.3683 5.36261 25.7004 5.4076C26.0325 5.4526 26.3465 5.58579 26.6097 5.79331C26.8728 6.00083 27.0756 6.2751 27.1968 6.58756C27.318 6.90002 27.3532 7.23926 27.2988 7.56996L24.4638 24.7662C24.1888 26.425 22.3688 27.3762 20.8476 26.55C19.5751 25.8587 17.6851 24.7937 15.9851 23.6825C15.1351 23.1262 12.5313 21.345 12.8513 20.0775C13.1263 18.9937 17.5013 14.9212 20.0013 12.5C20.9826 11.5487 20.5351 11 19.3763 11.875C16.4976 14.0475 11.8788 17.3512 10.3513 18.2812C9.00381 19.1012 8.3013 19.2412 7.4613 19.1012C5.9288 18.8462 4.50756 18.4512 3.34756 17.97C1.78006 17.32 1.85631 15.165 3.34631 14.5375L24.7213 5.53746Z"
        fill="#333333"
      />
    </svg>
  );
}
