export default function ({ style }) {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="FolderCopySharpIcon"
      style={style}
    >
      <path d="M3 6H1v15h19v-2H3z"></path>
      <path d="M23 4h-9l-2-2H5.01L5 17h18V4z"></path>
    </svg>
  );
}
