export default function ({style}) {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium account css-i4bv87-MuiSvgIcon-root"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="UnfoldMoreSharpIcon"
      style={style}
    >
      <path d="M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"></path>
    </svg>
  );
}
