export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      svg
      width={height}
      height={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      > */}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1251 12C16.1251 11.5858 15.7893 11.25 15.3751 11.25L4.40256 11.25L6.36321 9.56944C6.67771 9.29988 6.71413 8.8264 6.44456 8.51191C6.175 8.19741 5.70152 8.16099 5.38703 8.43056L1.88703 11.4306C1.72079 11.573 1.62512 11.7811 1.62512 12C1.62512 12.2189 1.72079 12.427 1.88703 12.5694L5.38703 15.5694C5.70152 15.839 6.175 15.8026 6.44456 15.4881C6.71413 15.1736 6.67771 14.7001 6.36322 14.4306L4.40256 12.75L15.3751 12.75C15.7893 12.75 16.1251 12.4142 16.1251 12Z"
        fill="#1C274C"
      />
      <path
        d="M9.37512 8C9.37512 8.70219 9.37512 9.05329 9.54363 9.3055C9.6166 9.41471 9.71037 9.50848 9.81958 9.58145C10.0718 9.74996 10.4229 9.74996 11.1251 9.74996L15.3751 9.74996C16.6178 9.74996 17.6251 10.7573 17.6251 12C17.6251 13.2426 16.6178 14.25 15.3751 14.25L11.1252 14.25C10.4229 14.25 10.0718 14.25 9.81952 14.4185C9.71036 14.4915 9.61662 14.5852 9.54367 14.6944C9.37512 14.9466 9.37512 15.2977 9.37512 16C9.37512 18.8284 9.37512 20.2426 10.2538 21.1213C11.1325 22 12.5465 22 15.3749 22L16.3749 22C19.2033 22 20.6175 22 21.4962 21.1213C22.3749 20.2426 22.3749 18.8284 22.3749 16L22.3749 8C22.3749 5.17158 22.3749 3.75736 21.4962 2.87868C20.6175 2 19.2033 2 16.3749 2L15.3749 2C12.5465 2 11.1325 2 10.2538 2.87868C9.37512 3.75736 9.37512 5.17157 9.37512 8Z"
        fill="#1C274C"
      />
      {/* </g> */}
    </svg>
  );
};
