export default function () {
  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0875 1.66256C21.425 0.887557 19.625 0.325057 17.75 5.74977e-05C17.7336 -0.000468256 17.7172 0.00261685 17.7021 0.00909595C17.687 0.015575 17.6735 0.0252908 17.6625 0.0375578C17.4375 0.450058 17.175 0.987557 17 1.40006C15.0113 1.10006 12.9888 1.10006 11 1.40006C10.825 0.975057 10.5625 0.450058 10.325 0.0375578C10.3125 0.0125578 10.275 5.74977e-05 10.2375 5.74977e-05C8.36252 0.325057 6.57502 0.887557 4.90002 1.66256C4.88752 1.66256 4.87502 1.67506 4.86252 1.68756C1.46252 6.77506 0.525024 11.7251 0.987524 16.6251C0.987524 16.6501 1.00002 16.6751 1.02502 16.6876C3.27502 18.3376 5.43752 19.3376 7.57502 20.0001C7.61252 20.0126 7.65002 20.0001 7.66252 19.9751C8.16252 19.2876 8.61252 18.5626 9.00002 17.8001C9.02502 17.7501 9.00002 17.7001 8.95002 17.6876C8.23752 17.4126 7.56252 17.0876 6.90002 16.7126C6.85002 16.6876 6.85002 16.6126 6.88752 16.5751C7.02502 16.4751 7.16252 16.3626 7.30002 16.2626C7.32502 16.2376 7.36252 16.2376 7.38752 16.2501C11.6875 18.2126 16.325 18.2126 20.575 16.2501C20.6 16.2376 20.6375 16.2376 20.6625 16.2626C20.8 16.3751 20.9375 16.4751 21.075 16.5876C21.125 16.6251 21.125 16.7001 21.0625 16.7251C20.4125 17.1126 19.725 17.4251 19.0125 17.7001C18.9625 17.7126 18.95 17.7751 18.9625 17.8126C19.3625 18.5751 19.8125 19.3001 20.3 19.9876C20.3375 20.0001 20.375 20.0126 20.4125 20.0001C22.5625 19.3376 24.725 18.3376 26.975 16.6876C27 16.6751 27.0125 16.6501 27.0125 16.6251C27.5625 10.9626 26.1 6.05006 23.1375 1.68756C23.125 1.67506 23.1125 1.66256 23.0875 1.66256ZM9.65003 13.6376C8.36252 13.6376 7.28752 12.4501 7.28752 10.9876C7.28752 9.52506 8.33753 8.33756 9.65003 8.33756C10.975 8.33756 12.025 9.53756 12.0125 10.9876C12.0125 12.4501 10.9625 13.6376 9.65003 13.6376ZM18.3625 13.6376C17.075 13.6376 16 12.4501 16 10.9876C16 9.52506 17.05 8.33756 18.3625 8.33756C19.6875 8.33756 20.7375 9.53756 20.725 10.9876C20.725 12.4501 19.6875 13.6376 18.3625 13.6376Z"
        fill="#333333"
      />
    </svg>
  );
}
