export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path d="M2130 5025 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path
          d="M2776 4928 c-61 -104 -95 -222 -103 -352 l-6 -99 63 6 c35 4 74 7 86
7 22 0 24 4 24 53 0 72 33 198 72 274 18 34 30 64 27 66 -14 12 -129 87 -133
87 -3 0 -16 -19 -30 -42z"
        />
        <path d="M260 4855 l0 -86 83 3 82 3 3 83 3 82 -86 0 -85 0 0 -85z" />
        <path
          d="M1258 4917 c-113 -39 -213 -120 -261 -212 l-15 -30 70 -36 71 -36 66
67 c66 67 129 100 188 100 19 0 23 -5 23 -33 0 -18 -13 -73 -30 -121 -47 -141
-54 -190 -30 -243 35 -79 98 -89 310 -48 69 13 127 21 129 19 3 -2 -9 -66 -27
-142 -44 -197 -45 -199 -33 -235 25 -75 103 -114 190 -96 112 23 108 23 125
-4 9 -14 21 -66 28 -118 11 -95 38 -189 61 -207 18 -15 141 85 131 107 -4 9
-13 57 -19 106 -7 50 -18 109 -24 132 -13 46 -61 115 -103 146 -33 25 -118 33
-173 18 -21 -6 -40 -9 -42 -8 -1 2 14 70 33 152 40 171 44 223 20 259 -50 77
-149 82 -422 21 -21 -5 -21 -2 12 100 42 129 45 209 12 275 -34 66 -66 84
-151 88 -55 2 -88 -3 -139 -21z"
        />
        <path d="M3670 4855 l0 -86 83 3 82 3 3 83 3 82 -86 0 -85 0 0 -85z" />
        <path
          d="M2378 4548 c-38 -17 -68 -36 -68 -42 0 -13 188 -448 198 -457 8 -8
152 52 152 63 0 14 -199 463 -207 464 -4 1 -38 -11 -75 -28z"
        />
        <path
          d="M3004 4376 c-89 -105 -234 -331 -234 -363 0 -8 128 -73 144 -73 2 0
21 29 41 65 51 91 118 189 178 258 l50 59 -49 50 c-73 74 -72 74 -130 4z"
        />
        <path
          d="M4222 4299 c-183 -42 -379 -133 -532 -247 -91 -68 -197 -172 -234
-230 -17 -27 -51 -59 -94 -86 -154 -99 -317 -269 -465 -484 -40 -59 -76 -101
-83 -98 -6 2 -68 24 -135 47 l-124 43 -339 -119 -339 -119 -40 50 c-76 92
-233 239 -323 301 -327 224 -683 290 -992 184 -108 -37 -180 -82 -257 -158
-104 -105 -155 -227 -155 -373 0 -134 46 -248 132 -332 l51 -49 48 72 48 72
-23 18 c-12 9 -35 41 -51 71 -27 48 -30 61 -30 148 0 83 3 102 27 151 114 231
447 320 793 212 103 -32 251 -109 340 -175 68 -51 255 -232 255 -247 0 -4
-199 -77 -442 -162 -342 -119 -451 -161 -478 -184 -157 -135 -751 -669 -764
-687 -22 -31 -20 -62 7 -93 14 -15 173 -97 422 -215 l400 -189 5 -345 c5 -329
6 -346 25 -365 11 -10 391 -175 845 -366 606 -255 831 -345 850 -342 51 11
1645 688 1667 709 l23 21 0 342 0 342 408 192 c224 106 415 202 425 213 28 33
22 83 -15 122 -56 57 -753 673 -778 686 -12 7 -131 50 -264 96 -133 47 -248
87 -256 90 -16 6 134 106 295 196 119 67 337 165 453 203 50 16 82 32 79 39
-2 6 -12 41 -23 79 -11 38 -24 67 -32 67 -26 0 -272 -95 -388 -150 -174 -82
-379 -206 -555 -335 l-36 -26 -279 97 c-153 53 -283 100 -288 105 -9 8 44 90
135 205 65 83 223 244 239 244 5 0 13 -12 16 -27 21 -85 96 -185 171 -230 108
-63 225 -66 343 -7 256 128 269 457 22 566 -24 11 -75 25 -114 31 l-71 12 76
53 c145 100 304 168 472 201 64 13 90 22 88 32 -1 8 -4 44 -8 82 l-7 67 -31
-1 c-18 0 -69 -9 -115 -20z m-355 -616 c107 -50 92 -192 -27 -253 -125 -63
-249 -2 -281 139 -15 68 -4 86 69 109 37 12 78 23 92 26 40 7 109 -3 147 -21z
m-1220 -650 c46 -15 83 -31 83 -35 0 -5 -33 -75 -73 -156 -96 -191 -133 -280
-121 -292 10 -9 146 -62 149 -58 1 2 12 30 25 63 24 62 162 340 184 370 11 15
32 10 243 -64 127 -44 243 -85 257 -90 24 -10 22 -13 -90 -126 -63 -64 -114
-120 -114 -125 0 -5 27 -31 61 -59 l61 -51 134 134 c74 74 144 138 155 141 23
8 413 -125 401 -136 -4 -4 -330 -137 -726 -295 l-718 -287 -697 278 c-383 153
-712 285 -731 293 l-34 14 354 124 355 125 22 -27 c12 -15 45 -71 73 -125 l51
-98 37 16 c54 22 112 53 112 58 0 9 -71 148 -97 188 -13 21 -22 42 -18 45 8 9
545 200 564 201 8 1 52 -11 98 -26z m-266 -1188 c1 -5 -153 -151 -344 -323
l-346 -313 -723 342 c-398 188 -724 343 -726 344 -2 2 139 129 312 283 l315
280 754 -301 c415 -166 756 -306 758 -312z m2194 320 c160 -141 291 -260 293
-264 2 -7 -1423 -686 -1440 -686 -11 0 -709 627 -704 632 2 2 345 141 762 308
739 295 760 303 779 285 11 -10 151 -133 310 -275z m-2109 -1942 c-2 -3 -329
132 -725 299 l-721 303 0 252 c0 139 4 253 9 253 4 0 149 -67 322 -148 173
-82 325 -151 338 -155 13 -3 33 -2 45 3 11 4 181 152 376 328 l355 319 3 -725
c1 -399 0 -727 -2 -729z m553 1122 c183 -165 345 -305 359 -312 15 -7 36 -10
47 -7 11 3 162 73 336 155 174 82 319 149 323 149 3 0 6 -114 6 -253 l0 -254
-707 -298 c-390 -164 -716 -300 -725 -303 -17 -4 -18 35 -18 728 l0 731 23
-18 c13 -10 173 -153 356 -318z"
        />
        <path
          d="M495 4276 c-232 -54 -409 -210 -463 -408 l-10 -37 46 -10 c127 -26
117 -28 137 26 72 195 295 303 540 264 205 -33 388 -131 548 -294 l87 -88 65
47 c36 26 65 51 65 55 0 3 -30 39 -67 80 -160 172 -360 294 -577 350 -104 27
-286 34 -371 15z"
        />
        <path d="M4770 4005 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M1700 3495 l0 -85 90 0 90 0 0 85 0 85 -90 0 -90 0 0 -85z" />
        <path
          d="M5050 3504 c-14 -2 -64 -9 -112 -15 -100 -13 -96 -7 -76 -116 l12
-65 40 6 c23 4 76 10 119 15 43 5 81 11 83 14 3 3 2 42 -2 86 -7 86 -4 83 -64
75z"
        />
        <path d="M770 2985 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
      </g>
    </svg>
  );
};
