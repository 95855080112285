import React from 'react';

import * as reactSvgs from '../assets/reactSvgs';

import styles from '../styles/Footer.module.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles['footer-left']}>
        <Link to="/">
          <h1>Viral Waitlist</h1>
        </Link>
      </div>
      <div className={styles['footer-right']}>
        <div className={styles['icon-container']}>
          <Link>
            <reactSvgs.twitterIcon />
          </Link>
          <Link>
            <reactSvgs.discordIcon />
          </Link>
          <Link>
            <reactSvgs.telegramIcon />
          </Link>
        </div>
        <div className={styles['link-container']}>
          <Link>Terms of service</Link>
          <Link>Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
