import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import config from '../config/config';
import * as reactSvgs from '../assets/reactSvgs';
import { general } from '../helpers/General';

import styles from '../styles/UserInvite.module.css';

function UserInvitePage() {
  const [waitlist, setWaitlist] = useState({});
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = general.getUser();

  const projectId = searchParams.get('projectId');
  const waitlistId = searchParams.get('waitlistId');
  const referralCode = searchParams.get('referralCode');

  // Embed variables
  const isEmbed = window.location.pathname.includes('embed');

  let backgroundColor = '';
  let foregroundColor = '';
  let headingColor = '';
  let fontColor = '';
  let inputBackground = '';
  let inputOutline = '';
  let inputFontColor = '';
  let buttonBackground = '';

  if (isEmbed) {
    backgroundColor = searchParams.get('backgroundColor');
    foregroundColor = searchParams.get('foregroundColor');
    headingColor = searchParams.get('headingColor');
    fontColor = searchParams.get('fontColor');
    inputBackground = searchParams.get('inputBackground');
    inputOutline = searchParams.get('inputOutline');
    inputFontColor = searchParams.get('inputFontColor');
    buttonBackground = searchParams.get('buttonBackground');
  }

  useEffect(() => {
    localStorage.removeItem('prevUrl');
    general.fireTrackingEvent('loaded', 'join_waitlist');
    (async () => {
      try {
        const response = await axios.get(
          `${config.BACKEND_URL}api/viralwaitlist/waitlists/${waitlistId}`,
          {
            headers: {
              token: user.token,
            },
            params: {
              userId: user.userId,
              projectId: projectId,
              questId: waitlistId,
              count: true,
            },
          }
        );

        if (response.data.success === false) {
          console.log(response.data.error);
          throw new Error(response.data.error);
        }

        if (response.data.claimStatus === true) {
          navigate(`/referral?waitlistId=${waitlistId}&projectId=${projectId}`);
        }

        setWaitlist(response.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.success === false) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleChangeAction = (criteriaId, value) => {
    let isNewCriteria = true;
    for (let criteria of actions) {
      if (criteria.criteriaId === criteriaId) {
        criteria.answer[0] = value;
        isNewCriteria = false;
      }
    }
    if (isNewCriteria) {
      actions.push({ criteriaId, answer: [value] });
    }
    setActions([...actions]);
  };

  const handleSubmit = async (event) => {
    general.fireTrackingEvent('submit_waitlist', 'join_waitlist');
    try {
      event.preventDefault();
      setSubmitLoading(true);

      const response = await axios.post(
        `${config.BACKEND_URL}api/viralwaitlist/waitlists/verify`,
        {
          criterias: actions,
          projectId: projectId,
          referralCode: referralCode,
        },
        {
          headers: {
            token: user.token,
          },
          params: {
            userId: user.userId,
            waitlistId: waitlistId,
          },
        }
      );

      if (response.data.success === false) {
        throw new Error(response.data.error);
      }

      let navigateUrl = `/referral?waitlistId=${waitlistId}&projectId=${projectId}`;

      if (isEmbed) {
        navigateUrl = `/embed/referral${window.location.search}`;
      }

      navigate(navigateUrl);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const embedStyles = {
    main: {
      backgroundColor: backgroundColor || '',
    },
    'form-container-main': {
      backgroundColor: foregroundColor || '',
    },
    heading: {
      background: headingColor || '',
      WebkitBackgroundClip: 'text',
    },
    'action-title': {
      color: fontColor || '',
    },
    'action-input': {
      backgroundColor: inputBackground || '',
      outlineColor: inputOutline || '',
      color: inputFontColor || '',
    },
    'submit-button': {
      background: buttonBackground || '',
    },
  };

  return (
    <div className={styles.main} style={embedStyles.main}>
      <div
        className={styles['form-container-main']}
        style={embedStyles['form-container-main']}
      >
        <div className={styles['heading-container']}>
          <h1 className={styles.heading} style={embedStyles.heading}>
            Join waitlist
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles['form-container']}>
            {waitlist.eligibilityData && (
              <>
                {waitlist.eligibilityData?.map((action, index) => (
                  <div key={index} className={styles['action-container']}>
                    <p
                      className={styles['action-title']}
                      style={embedStyles['action-title']}
                    >
                      {action.data.metadata.title}
                    </p>
                    <input
                      type="text"
                      placeholder={
                        action.userAnswer
                          ? action.userAnswer[0]
                          : 'Enter answer'
                      }
                      required={true}
                      onChange={(event) =>
                        handleChangeAction(
                          action.data.criteriaId,
                          event.target.value
                        )
                      }
                      style={embedStyles['action-input']}
                    />
                  </div>
                ))}
                <button
                  type="submit"
                  className={styles['submit-button']}
                  style={embedStyles['submit-button']}
                >
                  Submit
                </button>
              </>
            )}
            {loading && (
              <div className={styles['loading-container']}>
                <reactSvgs.loadingIcon />
              </div>
            )}
          </div>
        </form>
      </div>
      {submitLoading && (
        <div className={styles['submit-loading-overlay']}>
          <div className={styles['submit-loading-container']}>
            <h1>submitting</h1>
            <reactSvgs.loadingIconInfinity />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInvitePage;
