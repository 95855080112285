class config {
  static BACKEND_URL = 'https://addons.questprotocol.xyz/';
  static APP_URL = window.location.origin;
  static GOOGLE_CLIENT_ID =
    '172141279155-kjj5kub2d40hmkf6sd7d0p4t17f16mg1.apps.googleusercontent.com';
  static STRIPE_PUBLISHABLE_KEY =
    'pk_test_51IGxpeHv3bPcUa5dtAAgA2TZPWjga0FPxWlK3GAnWUfzRXzO8l6Kc3zF2WBpjrvFHAle0Cy3Jqxc7djZxptd9mHe00KjsN2Im7';
  static CRISP_KEY = 'd64124d4-2176-447d-b2c9-0b2025d2eb70';
  static API_KEY = 'k-586026b4-ff7d-4120-a8c6-bdfe40348c28';
  static API_SECRET =
    's-41b8affe-2db2-4b5d-8096-fd56231a197ef44e7e9c-17b8-4b87-8e09-cd60af26d761';
  static VIRAL_WAITLIST_ENTITY_ID = 'e-4dd73acd-ca08-43ff-a1ec-1251ef4daed5';
  static IPFS_PREFIX = 'https://pin.questprotocol.xyz/ipfs/';
  static QUEST_PROTOCOL_URL = 'https://staging.questprotocol.xyz';
}
export default config;
