import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Referral from './Referral';
import LandingPage from './LandingPage';
import Overview from './Overview';
import Submissions from './Submissions';
import Login from './Login';
import UserInvitePage from './UserInvitePage';
import Projects from './Projects';
import CreateWaitlistPage from './CreateWaitlistPage';
import Upgrade from './Upgrade';
import CannyWidget from './CannyWidget';
import SafeRoute from './SafeRoute';
import PricingPage from './PricingPage';
import StripePayment from './StripePayment';
import config from '../config/config';
import pages from '.';
import Home from './Home/Home';

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/referral"
            element={<SafeRoute Component={Referral} />}
          />
          <Route
            path="/overview"
            element={<SafeRoute Component={Overview} />}
          />
          <Route
            path="/submissions"
            element={<SafeRoute Component={Submissions} />}
          />
          <Route
            path="/account"
            element={<SafeRoute Component={pages.Account} />}
          />
          <Route
            path="/projects"
            element={<SafeRoute Component={Projects} />}
          />
          <Route
            path="/create-waitlist"
            element={<SafeRoute Component={CreateWaitlistPage} />}
          />
          <Route
            path="/invite"
            element={<SafeRoute Component={UserInvitePage} />}
          />
          <Route path="/upgrade" element={<SafeRoute Component={Upgrade} />} />
          <Route
            path="/payment/:creditsTierId"
            element={
              <SafeRoute>
                <Elements stripe={stripePromise}>
                  <StripePayment />
                </Elements>
              </SafeRoute>
            }
          />
          <Route
            path="/feedback"
            element={<SafeRoute Component={CannyWidget} />}
          />
          <Route
            path="/embed/invite"
            element={<SafeRoute Component={UserInvitePage} />}
          />
          <Route
            path="/embed/referral"
            element={<SafeRoute Component={Referral} />}
          />
          <Route path="/embed/login" element={<Login />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
