import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import config from '../config/config';
import Sidebar from './Sidebar';
import { general } from '../helpers/General';
import * as reactSvgs from '../assets/reactSvgs';

import styles from '../styles/Submissions.module.css';

function Submissions() {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchParams] = useSearchParams();
  const user = general.getUser();

  const waitlistId = searchParams.get('waitlistId');

  useEffect(() => {
    general.fireTrackingEvent("loaded", "submissions_page");
  }, []);

  useEffect(() => {
    if (waitlistId) {
      (async () => {
        try {
          setLoading(true);

          const response = await axios.get(
            `${config.BACKEND_URL}api/viralwaitlist/waitlists/submissions`,
            {
              headers: {
                token: user.token,
                userId: user.userId,
              },
              params: {
                projectId: user.projectId,
                waitlistId: waitlistId,
                page,
                limit: 10,
              },
            }
          );

          if (response.data.success === false) {
            throw new Error(response.data.error);
          }

          setSubmissions(response.data.data);
          setFilteredSubmissions(response.data.data);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [waitlistId]);

  const handleSearch = (text) => {
    const filtered = submissions.filter((submission) =>
      submission.email.toLocaleLowerCase().includes(text.toLocaleLowerCase())
    );

    setFilteredSubmissions([...filtered]);
  };

  const loadMoreSubmissions = async () => {
    setPage((prev) => prev + 1);
    try {
      setLoading(true);

      const response = await axios.get(
        `${config.BACKEND_URL}api/viralwaitlist/waitlists/submissions`,
        {
          headers: {
            token: user.token,
          },
          params: {
            userId: user.userId,
            projectId: user.projectId,
            waitlistId: waitlistId,
            page,
            limit: 10,
          },
        }
      );

      if (response.data.success === false) {
        throw new Error(response.data.error);
      }

      setSubmissions([...submissions, ...response.data.data]);
      setFilteredSubmissions([...filteredSubmissions, ...response.data.data]);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles.main}>
        <h1 className={styles.heading}>Submissions</h1>
        <div className={styles['table-container-main']}>
          <div className={styles['filter-container']}>
            <div className={styles['search-bar-container']}>
              <reactSvgs.searchIcon stroke="grey" />
              <input
                type="text"
                placeholder="Search"
                onChange={(event) => handleSearch(event.target.value)}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Position</th>
                <th>Email</th>
                <th>Referral id</th>
                <th>Users Reffered</th>
                <th>Reffered By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubmissions.map((submission, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{submission.email}</td>
                  <td>{submission.referralCode}</td>
                  <td>{submission.usersReferred}</td>
                  <td>{submission.referredBy}</td>
                  <td>{general.formatDate(submission.submittedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && filteredSubmissions.length === 0 && (
            <div className={styles['no-answer-container']}>
              <h2>No submissions recieved</h2>
            </div>
          )}
          {loading && submissions.length == 0 && (
            <div className={styles['loading-container']}>
              <reactSvgs.loadingIcon />
            </div>
          )}
          {(page < totalPages || loading) && submissions.length > 0 && (
            <div className={styles['load-button-container']}>
              <button onClick={loadMoreSubmissions}>
                {loading ? (
                  <reactSvgs.loadingIcon
                    strokeWidth="5"
                    width="20px"
                    stroke="#4b5563"
                  />
                ) : (
                  'Load more'
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Submissions;
