import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import config from '../config/config';
import * as svgs from '../assets/svgs';
import * as reactSvgs from '../assets/reactSvgs';
import { general } from '../helpers/General';

import styles from '../styles/Login.module.css';

function Login() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [timer, setTimer] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [OTP, setOTP] = useState('');
  const [verify, setVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [thirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] =
    useState(true);
  const [generalLoading, setGeneralLoading] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = general.getUser();

  const googleCode = searchParams.get('code');

  // Embed variables
  const isEmbed = window.location.pathname.includes('embed');

  let backgroundColor = '';
  let foregroundColor = '';
  let headingColor = '';
  let fontColor = '';
  let inputBackground = '';
  let inputOutline = '';
  let inputFontColor = '';
  let buttonBackground = '';
  let buttonFontColor = '';

  if (isEmbed) {
    backgroundColor = searchParams.get('backgroundColor');
    foregroundColor = searchParams.get('foregroundColor');
    headingColor = searchParams.get('headingColor');
    fontColor = searchParams.get('fontColor');
    inputBackground = searchParams.get('inputBackground');
    inputOutline = searchParams.get('inputOutline');
    inputFontColor = searchParams.get('inputFontColor');
    buttonBackground = searchParams.get('buttonBackground');
    buttonFontColor = searchParams.get('buttonFontColor');
  }

  useEffect(() => {
    general.fireTrackingEvent("loaded", "login_page");
  }, []);

  useEffect(() => {
    try {
      if (window.localStorage) {
      } else {
        setThirdPartyCookiesEnabled(false);
      }
    } catch (error) {
      setThirdPartyCookiesEnabled(false);
    }
  }, [user]);

  useEffect(() => {
    if (showTimer) {
      if (timer > 0) {
        setTimeout(() => setTimer(timer - 1), 1000);
      } else {
        setShowTimer(false);
      }
    }
  }, [timer]);

  useEffect(() => {
    if (googleCode) {
      (async () => {
        try {
          setGeneralLoading((prev) => [...prev, 'GOOGLE']);

          const response = await axios.post(
            `${config.BACKEND_URL}api/viralwaitlist/google/verify`,
            { code: googleCode, redirectUri: config.APP_URL + '/login' }
          );

          if (response.data.success === false) {
            throw new Error(response.data.error);
          }

          await getAdminEntity(response.data.token, response.data.userId);
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        } finally {
          const filteredLoading = generalLoading.filter(
            (item) => item != 'GOOGLE'
          );
          setGeneralLoading([...filteredLoading]);
        }
      })();
    }
  }, [googleCode]);

  function handleChange(OTP) {
    setOTP(OTP);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    general.fireTrackingEvent("email_button", "login_page");
    setIsLoading(true);
    const name = event.target.name.value;
    const email = event.target.email.value;

    axios
      .post(
        `${config.BACKEND_URL}api/viralwaitlist/users/email-login/send-otp`,
        { email: email }
      )
      .then((res) => {
        if (res.data.success === false) {
          throw new Error(res.data.error);
        }

        setIsLoading(false);
        setName(name);
        setEmail(email);
        setShowTimer(true);
        setTimer(120);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setIsLoading(false);
        setShowTimer(false);
      });
  };

  const handleSubmit = async () => {
    setVerify(true);
    try {
      const response = await axios.post(
        `${config.BACKEND_URL}api/viralwaitlist/users/email-login/verify-otp`,
        { email: email, name: name, otp: OTP }
      );

      if (response.data.success === false) {
        throw new Error(response.data.error);
      }

      if (!response.data.token || !response.data.userId) {
        throw new Error('Login failed');
      }

      setShowTimer(false);
      setOTP('');

      await getAdminEntity(response.data.token, response.data.userId);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setVerify(false);
    }
  };

  const getAdminEntity = async (token, userId) => {
    try {
      if (!userId || !token) {
        throw new Error('Login failed');
      }

      const user = { userId, token };

      const response = await axios.get(
        `${config.BACKEND_URL}api/viralwaitlist/projects`,
        {
          headers: {
            token,
          },
          params: {
            userId,
          },
        }
      );

      if (response.data.success === false) {
        throw new Error(response.data.error);
      }

      const prevUrl = localStorage.getItem('prevUrl');
      localStorage.removeItem('prevUrl');
      let navUrl = '';

      if (response.data.data.length > 0) {
        const project = response.data.data[0];
        user.projectId = project.id;

        general.setProject({
          image: project.imageUrl || '',
          description: project.description || '',
          name: project.name,
          website: project.website || '',
        });

        navUrl = '/create-waitlist';
      } else {
        navUrl = '/account';
      }

      if (prevUrl) {
        navUrl = prevUrl;
      }

      if (isEmbed) {
        navUrl = '/embed/invite' + window.location.search;
      }

      general.setUser({ ...user });
      navigate(navUrl);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const embedStyles = {
    main: {
      backgroundColor: backgroundColor || '',
    },
    'form-container': {
      backgroundColor: foregroundColor || '',
    },
    heading: {
      color: headingColor || '',
    },
    'action-title': {
      color: fontColor || '',
    },
    'action-input': {
      backgroundColor: inputBackground || '',
      outlineColor: inputOutline || '',
      color: inputFontColor || '',
    },
    'submit-button': {
      background: buttonBackground || '',
      color: buttonFontColor || '',
    },
    'otp-input': {
      width: '100% !important',
      height: '50px',
      borderRadius: '7px',
      border: '1px solid #ccc',
      marginLeft: '8px',
      marginRight: '8px',
      background: 'white',
      fontSize: '20px',
    },
  };

  return (
    <div className={styles.main} style={embedStyles.main}>
      {isEmbed === false && (
        <div className={styles['heading-container']}>
          <img src={svgs.viralWaitlistLogo} alt="viral waitlist logo" />
          <h1>
            ViralWait<span>List</span>
          </h1>
        </div>
      )}
      <div
        className={styles['form-container']}
        style={embedStyles['form-container']}
      >
        <h3 style={embedStyles.heading}>Get Started with virality!</h3>
        {isEmbed === false && (
          <>
            <Link
              to={
                generalLoading.includes('GOOGLE')
                  ? '#'
                  : `https://accounts.google.com/o/oauth2/auth?client_id=${config.GOOGLE_CLIENT_ID}&redirect_uri=${config.APP_URL}/login&scope=profile%20email&response_type=code`
              }
              onClick={() => {
                general.fireTrackingEvent("google_login_button", "login_page")
              }}
            >
              <button
                className={styles['google-button']}
                disabled={generalLoading.includes('GOOGLE')}
              >
                <reactSvgs.googleIcon />
                {generalLoading.includes('GOOGLE')
                  ? 'Logging you in...'
                  : 'Continue with google'}
              </button>
            </Link>
            <div className={styles['divider-container']}>
              <div className={styles['divider']}></div>
              <p>OR</p>
              <div className={styles['divider']}></div>
            </div>
          </>
        )}

        <form className={styles.form} onSubmit={handleFormSubmit}>
          <div className={styles['action-container']}>
            <p
              className={styles['action-title']}
              style={embedStyles['action-title']}
            >
              Name
            </p>
            <input
              type="text"
              placeholder="Your name"
              name="name"
              required={true}
              style={embedStyles['action-input']}
            />
          </div>
          <div className={styles['action-container']}>
            <p
              className={styles['action-title']}
              style={embedStyles['action-title']}
            >
              Email
            </p>
            <input
              type="email"
              placeholder="Your email"
              name="email"
              required={true}
              style={embedStyles['action-input']}
            />
          </div>
          <button
            type="submit"
            className={styles['submit-button']}
            style={embedStyles['submit-button']}
          >
            <span>
              {isLoading ? (
                <div className="flex items-center">
                  <reactSvgs.loadingIcon
                    width="25px"
                    stroke="white"
                    strokeWidth="5"
                  />
                  Loading...
                </div>
              ) : (
                <span className="text-white">
                  {email === '' ? 'Send OTP' : 'Resend OTP'}
                </span>
              )}
            </span>
          </button>
        </form>
        {showTimer && (
          <div className={styles['otp-container']}>
            <div
              className={styles['otp-box']}
              style={embedStyles['form-container']}
            >
              <OTPInput
                on
                onChange={handleChange}
                value={OTP}
                inputStyle={styles['otp-input']}
                numInputs={6}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              <p className="mt-2">{timer} seconds</p>

              <button
                className={styles['submit-button']}
                onClick={handleSubmit}
                disabled={verify}
                style={embedStyles['submit-button']}
              >
                {verify ? <span>Verifying...</span> : <span>Submit OTP</span>}
              </button>
            </div>
          </div>
        )}
        {isEmbed && thirdPartyCookiesEnabled === false && (
          <div className={styles['otp-container']}>
            <div
              className={styles['otp-box']}
              style={embedStyles['form-container']}
            >
              <h3 style={embedStyles.heading}>
                Please enable third party cookies to access viral waitlist
                embeds
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
