import React, { useState, useEffect } from 'react';
import { HashRouter, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Sidebar from './Sidebar';
import Crisp from '../sdks/Crisp';
import * as reactSvgs from '../assets/reactSvgs';
import * as images from '../assets/images';
import * as svgs from '../assets/svgs';
import { general } from '../helpers/General';
import accountApis from '../helpers/accountApis';

import styles from '../styles/Account.module.css';
import config from '../config/config';

export default function Account() {
  const [loading, setLoading] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [account, setAccount] = useState({
    image: {
      url: '',
      uploadFile: false,
      file: null,
    },
    name: '',
    website: '',
    description: '',
  });
  const [generalLoading, setGeneralLoading] = useState({});

  const navigate = useNavigate();
  let user = general.getUser();
  let project = general.getProject();
  const newProject = project.name === '' || !project.name;

  useEffect(() => {
    Crisp.load();
    general.fireTrackingEvent('loaded', 'account_page');

    if (newProject === false) {
      let projectDetails = {
        ...(project.name.length > 0 && { name: project.name }),
        ...(project.description.length > 0 && {
          description: project.description,
        }),
        ...(project.website.length > 0 && { website: project.website }),
      };

      if (project.image.length > 0) {
        projectDetails.image = {
          url: project.image,
          uploadFile: false,
          file: null,
        };
      }

      setAccount({ ...account, ...projectDetails });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (submitButtonDisabled()) {
        return;
      }

      setLoading(true);
      let imageUrl = account.image.url;

      if (account.image.uploadFile === true && account.image.file) {
        imageUrl = await accountApis.uploadImage(account.image.file);
      }

      const payload = {
        name: account.name,
        website: account.website,
        imageUrl,
        description: account.description,
      };

      const response = await general.makeRequest({
        url: newProject ? '/projects' : `/projects/${user.projectId}/update`,
        body: payload,
        method: 'POST',
        query: {
          userId: user.userId,
        },
      });

      if (response.success === false) {
        throw new Error(response.error);
      }

      if (newProject) {
        user = general.setUser({
          ...user,
          projectId: response.entityId,
        });
      }

      project = general.setProject({
        description: account.description,
        image: imageUrl,
        name: account.name,
        website: account.website,
      });

      toast.success(
        `Account ${newProject ? 'created' : 'updated'} successfully`
      );
      navigate('/create-waitlist');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputImage = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        setAccount({
          ...account,
          image: {
            url: URL.createObjectURL(file),
            uploadFile: true,
            file,
          },
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleInputChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setAccount({ ...account, [field]: value });
  };

  const handleAutofill = async () => {
    general.fireTrackingEvent('autofill_button', 'account_page');
    try {
      setGeneralLoading({
        ...generalLoading,
        autoFill: true,
      });

      if (account.website.length === 0) {
        throw new Error('Please pass a url to fetch website metadata');
      }

      const metadata = await general.getWebsiteMetadata(account.website);

      if (!metadata) {
        throw new Error(
          'Unable to fetch website metadata due to security restrictions'
        );
      }

      setAccount({
        name: metadata.title,
        website: account.website,
        description: metadata.description,
        image: {
          file: null,
          uploadFile: false,
          url: metadata.faviconUrl ? metadata.faviconUrl : '',
        },
      });
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      delete generalLoading.autoFill;
      setGeneralLoading({ ...generalLoading });
    }
  };

  const submitButtonDisabled = () => {
    if (loading) {
      return true;
    } else if (general.isValidName(account.name) === false) {
      return true;
    } else if (account.description.length === 0) {
      return true;
    } else if (general.isValidURL(account.website) === false) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles['form-container']}>
            <h1>
              {project?.name?.length > 0 ? 'Update account' : 'Create Account'}
            </h1>
            <div className={styles['image-container']}>
              <div className={styles['image-container-left']}>
                <label htmlFor="image-file-input" className='cursor-pointer relative' onMouseOver={(e) => setMouseHover(true)} onMouseOut={(e) => setMouseHover(false)}>
                  <img
                    src={
                      account.image.url.length > 0
                        ? account.image.url
                        : svgs.viralWaitlistLogo
                    }
                    className={`${styles['profile-picture']} border border-[rgb(38,38,38)] drop-shadow-2xl`}
                    alt="profile"
                  />
                  { mouseHover &&
                    <div className='absolute top-0 w-full h-full flex items-center justify-center'>
                      <img src={images.upload} className='w-10' alt="" />
                    </div>
                  }
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className={styles['image-file-input']}
                  onChange={handleInputImage}
                  id='image-file-input'
                  style={{display: "none"}}
                />
                {account.image.url.length > 0 && (
                  <reactSvgs.cancelIcon
                    className={styles['delete-icon']}
                    fillForeground="#595959"
                    onClick={() => {
                      setAccount({
                        ...account,
                        image: {
                          url: '',
                          uploadFile: false,
                        },
                      });
                    }}
                  />
                )}
              </div>
              {/* <div className={styles['image-container-right']}>
                <input
                  type="text"
                  placeholder="Enter image url"
                  className={styles['image-url-input']}
                  onChange={(event) =>
                    setAccount({
                      ...account,
                      image: {
                        url: event.target.value,
                        uploadFile: false,
                      },
                    })
                  }
                />
              </div> */}
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles['input-container']}>
                <p>Website url</p>
                <div className={styles['input-field-container']}>
                  <input
                    type="url"
                    placeholder="https://yourwebsite.com"
                    required={true}
                    name="website"
                    onChange={handleInputChange}
                    value={account.website}
                  />
                  {account.website !== '' &&
                    !general.isValidURL(account.website) && (
                      <span className={styles['error-text']}>
                        Please enter a valid Url. E.g:
                        https://viralwaitlist.com/
                      </span>
                    )}
                  <button
                    onClick={handleAutofill}
                    disabled={
                      !general.isValidURL(account.website) &&
                      (generalLoading.autoFill ? false : true)
                    }
                    type="button"
                  >
                    {generalLoading.autoFill ? (
                      <reactSvgs.loadingIcon
                        width="25px"
                        strokeWidth="5"
                        stroke="white"
                      />
                    ) : (
                      'Auto fill'
                    )}
                  </button>
                </div>
              </div>
              <div className={styles['input-container']}>
                <p>Name</p>
                <input
                  type="text"
                  placeholder="Enter organization name"
                  required={true}
                  name="name"
                  onChange={handleInputChange}
                  value={account.name}
                />
                {account.name !== '' && !general.isValidName(account.name) && (
                  <span className={styles['error-text']}>
                    Name cannot start with numbers or special symbols
                  </span>
                )}
              </div>
              <div className={styles['input-container']}>
                <p>Description</p>
                <input
                  type="text"
                  placeholder="Enter organization description"
                  required={true}
                  name="description"
                  onChange={handleInputChange}
                  value={account.description}
                />
              </div>
              <button type="submit" disabled={submitButtonDisabled()}>
                {loading && (
                  <reactSvgs.loadingIcon
                    width="25px"
                    strokeWidth="10"
                    stroke="grey"
                  />
                )}
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
