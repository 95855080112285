import { useState } from "react";
import Content1 from "./Components/Content1";
import Content2 from "./Components/Content2";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Navbar from "./Components/Navbar";
import { motion } from 'framer-motion';

export default function Home() {
    const [openPopup, setOpenPopup] = useState(false);

    const clickHadnler = (e) => {
        if (document.getElementById("disabledClick").contains(e.target)) {
        } else if (document.getElementById("openPopupButton").contains(e.target)) {
        } else {
            setOpenPopup(false)
        }
    };

    return (
        <div className="bg-home-1 min-h-screen" onClick={(e) => clickHadnler(e)}>
            <div className="h-[95px] md:h-[111px]">
                <Navbar openPopup={openPopup} setOpenPopup={setOpenPopup} />
            </div>
            <Header />
            <Content1 />
            <Content2 />
            <Footer />
        </div>
    )
}