export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M4826 5104 c-465 -53 -978 -196 -1387 -387 -220 -102 -470 -257 -659
-407 -103 -82 -311 -283 -402 -387 l-46 -53 -84 40 c-437 205 -860 15 -1120
-503 -89 -179 -150 -392 -164 -573 -9 -128 17 -174 100 -174 27 0 47 11 93 50
113 98 276 159 424 160 51 0 72 -5 90 -19 l24 -19 -19 -49 c-47 -121 -116
-326 -137 -410 -20 -80 -22 -98 -12 -145 10 -45 9 -54 -4 -63 -9 -5 -50 -32
-91 -58 -154 -99 -244 -208 -321 -389 -53 -123 -114 -365 -137 -537 -17 -128
-16 -135 1 -166 33 -63 63 -65 305 -25 238 40 459 115 597 202 80 50 181 160
246 266 37 61 55 81 66 77 49 -19 111 -16 205 9 90 25 269 86 389 132 l49 19
20 -25 c19 -23 20 -34 15 -120 -10 -156 -59 -279 -157 -393 -39 -46 -50 -66
-50 -93 0 -83 46 -109 174 -100 372 28 787 231 987 483 190 240 221 518 89
801 l-40 84 53 46 c104 90 305 299 385 399 421 530 706 1269 797 2063 18 163
19 191 3 225 -26 57 -71 63 -282 39z m80 -251 c-25 -208 -73 -458 -128 -658
-17 -60 -33 -120 -35 -132 -3 -13 -8 -23 -12 -23 -9 0 -696 689 -691 693 8 6
146 44 275 76 150 38 474 94 569 99 l28 2 -6 -57z m-663 -611 l418 -418 -47
-120 c-96 -241 -250 -518 -398 -717 -288 -388 -739 -753 -1233 -1001 -51 -25
-98 -46 -105 -46 -17 0 -938 921 -938 938 0 37 171 344 290 522 340 508 724
852 1225 1100 133 66 346 159 365 160 3 0 193 -188 423 -418z m-2206 -466 c28
-8 77 -25 108 -40 53 -24 56 -27 44 -45 -136 -189 -253 -375 -344 -546 -32
-60 -59 -111 -60 -113 -1 -1 -15 5 -32 13 -42 21 -193 29 -287 16 -67 -9 -192
-46 -260 -76 -17 -7 -18 -5 -12 29 4 20 19 72 33 114 105 317 305 562 522 638
78 28 205 32 288 10z m228 -1516 c225 -225 407 -411 405 -413 -10 -8 -182 -67
-285 -97 -68 -20 -118 -29 -134 -25 -32 8 -505 476 -522 517 -9 23 -8 40 5 91
27 98 109 337 115 337 3 0 190 -184 416 -410z m1464 -97 c65 -128 80 -294 37
-414 -46 -132 -157 -262 -309 -362 -112 -74 -188 -111 -326 -158 -132 -44
-157 -48 -146 -21 62 145 90 285 82 419 -3 54 -12 106 -21 124 -9 18 -15 33
-14 34 2 1 48 26 103 55 176 93 401 234 524 327 24 18 45 33 47 33 2 0 12 -17
23 -37z m-1892 -325 c90 -90 163 -169 163 -176 0 -53 -138 -239 -217 -293 -98
-68 -290 -136 -488 -174 -123 -23 -119 -25 -110 43 20 169 112 442 184 545 54
79 238 216 291 217 8 0 88 -73 177 -162z"
        />
        <path
          d="M3531 4099 c-95 -12 -199 -65 -276 -142 -100 -100 -145 -210 -145
-353 0 -278 216 -494 494 -494 143 0 253 45 353 145 150 150 189 372 99 562
-96 201 -297 309 -525 282z m195 -219 c230 -98 238 -427 12 -539 -51 -26 -73
-31 -133 -31 -170 0 -295 125 -295 296 0 59 5 81 31 132 71 143 238 205 385
142z"
        />
        <path
          d="M2995 3128 c-31 -17 -165 -150 -181 -180 -48 -92 42 -182 134 -134
32 17 165 151 181 183 42 83 -53 176 -134 131z"
        />
        <path
          d="M2593 2712 c-69 -11 -98 -110 -46 -164 51 -54 151 -26 165 45 6 29
-8 82 -26 96 -19 16 -66 28 -93 23z"
        />
        <path
          d="M708 2262 c-164 -163 -168 -169 -168 -209 0 -63 34 -98 94 -98 l46 0
165 165 c178 178 191 199 161 258 -20 39 -44 52 -93 52 -35 0 -47 -10 -205
-168z"
        />
        <path
          d="M752 1723 c-27 -5 -732 -712 -746 -747 -26 -68 45 -140 121 -121 19
4 148 126 381 358 383 382 391 392 357 456 -9 19 -31 39 -50 47 -19 8 -36 13
-37 13 -2 -1 -13 -4 -26 -6z"
        />
        <path
          d="M2295 1010 c-11 -4 -94 -83 -184 -174 l-164 -165 6 -43 c8 -57 43
-88 100 -88 40 0 46 4 209 168 158 158 168 170 168 205 0 49 -13 73 -50 92
-32 17 -55 18 -85 5z"
        />
        <path
          d="M790 869 c-30 -12 -705 -683 -726 -721 -37 -71 11 -148 94 -148 21 0
94 68 393 366 248 248 369 375 372 393 7 38 -17 87 -52 105 -34 18 -46 19 -81
5z"
        />
        <path
          d="M1590 869 c-30 -12 -705 -683 -726 -721 -37 -71 11 -148 94 -148 21
0 94 68 393 366 248 248 369 375 372 393 7 38 -17 87 -52 105 -34 18 -46 19
-81 5z"
        />
      </g>
    </svg>
  );
};
