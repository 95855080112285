export default function ({ fill = '#8A04D4' }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            opacity="0.12"
            d="M8.92993 16C13.3482 16 16.9299 12.4183 16.9299 8C16.9299 3.58172 13.3482 0 8.92993 0C4.51165 0 0.929932 3.58172 0.929932 8C0.929932 12.4183 4.51165 16 8.92993 16Z"
            fill={fill}
          />
          <path
            id="Vector_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3399 4.93073L7.5699 9.54073L6.3099 8.18073C6.19072 8.07676 6.03986 8.01621 5.88187 8.00895C5.72389 8.00169 5.56811 8.04813 5.4399 8.14073C5.31585 8.22787 5.2287 8.35807 5.19542 8.50597C5.16214 8.65386 5.18513 8.80885 5.2599 8.94072L6.7599 11.3907C6.9099 11.6107 7.1699 11.7507 7.4499 11.7507C7.7299 11.7507 7.9799 11.6107 8.1299 11.3907C8.3699 11.0807 12.9499 5.61073 12.9499 5.61073C13.5499 5.01073 12.8199 4.46073 12.3499 4.93073H12.3399Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
