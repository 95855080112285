import brandLogo from "../assets/svgs/viral-waitlist-logo.svg";
import menuBar from "../assets/homeImages/menuBar.svg";
import img1 from "../assets/homeImages/img1.webp";
import img2 from "../assets/homeImages/img2.webp";
import img3 from "../assets/homeImages/img3.webp";
import img4 from "../assets/homeImages/img4.webp";
import img5 from "../assets/homeImages/img5.webp";
import homeArrow from "../assets/homeImages/homeArrow.svg";
import msg1 from "../assets/homeImages/msg1.svg";
import msg2 from "../assets/homeImages/msg2.svg";
import msg3 from "../assets/homeImages/msg3.svg";
import appLogos from "../assets/homeImages/appLogos.svg";
import content2img1 from "../assets/homeImages/create.svg";
import content2img2 from "../assets/homeImages/embed.svg";
import content2img3 from "../assets/homeImages/viral.svg";
import stick from "../assets/homeImages/stick.svg";
import plusIcon from "../assets/homeImages/plusIcon.png";

export const importConfig = {
    brandLogo: brandLogo,
    home: {
        menuBar,
        img1,
        img2,
        img3,
        img4,
        img5,
        homeArrow,
        msg1,
        msg2,
        msg3,
        appLogos,
        content2img1,
        content2img2,
        content2img3,
        stick,
        plusIcon
    }
}