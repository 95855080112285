export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1372 4631 c-118 -29 -216 -84 -297 -165 -38 -39 -81 -92 -95 -118
-18 -34 -35 -52 -64 -65 -56 -25 -120 -94 -146 -157 l-23 -54 -226 -4 c-210
-3 -229 -5 -267 -25 -52 -28 -97 -79 -118 -134 -15 -40 -16 -153 -14 -1300 l3
-1256 30 -49 c19 -30 49 -60 79 -79 l49 -30 769 -3 c601 -2 768 -5 768 -15 0
-56 -90 -286 -153 -392 -25 -41 -51 -91 -57 -110 -22 -67 15 -152 81 -185 36
-19 60 -20 509 -20 449 0 473 1 509 20 66 33 103 118 81 185 -6 19 -32 69 -57
110 -63 106 -153 336 -153 392 0 10 167 13 768 15 l769 3 49 30 c30 19 60 49
79 79 l30 49 3 879 2 878 230 0 c206 0 235 2 284 20 72 27 139 88 173 158 l28
57 0 520 0 520 -24 52 c-28 62 -103 136 -169 166 l-47 22 -955 0 -955 0 -47
-22 c-67 -31 -142 -104 -169 -166 -21 -46 -24 -71 -27 -210 l-4 -157 -176 2
-175 3 -13 65 c-39 193 -191 369 -390 453 -134 57 -330 72 -472 38z m317 -156
c107 -26 187 -70 262 -146 93 -95 134 -178 134 -274 -1 -124 -66 -209 -211
-277 -58 -27 -83 -33 -160 -36 -171 -8 -307 54 -420 191 -50 62 -80 75 -123
53 -32 -17 -41 -34 -41 -76 0 -58 149 -204 270 -265 147 -74 341 -90 476 -38
11 4 14 -10 14 -83 0 -156 -35 -257 -117 -345 -82 -88 -182 -129 -313 -129
-85 0 -164 20 -226 56 -52 31 -126 108 -153 162 -24 47 -51 152 -51 200 0 40
-38 82 -75 82 -38 0 -83 26 -100 60 -19 37 -19 63 0 101 15 28 16 29 45 14 16
-8 41 -15 55 -15 35 0 75 42 75 77 -1 33 -12 48 -62 85 -22 17 -43 45 -53 73
-34 88 3 166 100 205 51 21 58 28 90 89 85 162 237 248 440 250 44 0 109 -6
144 -14z m3061 -27 c30 -15 53 -37 67 -63 l23 -40 -3 -487 c-2 -476 -2 -487
-23 -515 -11 -15 -36 -38 -54 -50 l-33 -23 -1150 0 c-688 0 -1147 4 -1141 9 5
5 70 59 144 121 87 72 143 126 158 152 22 41 22 46 22 417 0 371 1 377 23 416
14 26 37 48 67 63 l44 22 906 0 906 0 44 -22z m-3990 -557 c8 -15 3 -29 -24
-70 -81 -120 -67 -259 36 -358 18 -18 49 -39 68 -47 33 -13 37 -19 49 -78 33
-161 155 -317 304 -389 42 -21 47 -26 47 -56 0 -27 -4 -33 -22 -33 -41 0 -153
-32 -224 -65 -195 -89 -339 -240 -418 -439 -44 -110 -56 -197 -56 -412 l0
-194 -120 0 -120 0 0 1051 0 1051 29 29 29 29 206 0 c192 0 206 -1 216 -19z
m1840 -124 l0 -143 -148 -123 c-156 -129 -178 -154 -187 -217 -8 -50 26 -120
74 -151 l34 -23 873 0 874 0 0 -680 0 -680 -839 0 -839 0 -5 228 c-4 195 -7
237 -26 301 -23 77 -76 191 -114 245 -40 58 -142 158 -201 198 -103 71 -276
138 -354 138 -19 0 -22 5 -22 43 0 40 2 43 47 67 75 40 168 141 213 234 53
106 70 191 70 351 l0 131 63 64 c34 36 72 86 85 113 l23 47 190 0 189 0 0
-143z m-1040 -942 c0 -57 -3 -69 -25 -90 -15 -16 -36 -25 -55 -25 -19 0 -40 9
-55 25 -21 20 -25 34 -25 84 l0 60 63 4 c34 2 70 4 80 5 15 2 17 -7 17 -63z
m-289 -151 c21 -40 93 -99 137 -112 88 -27 167 -7 233 58 25 25 51 54 57 64 9
19 14 19 73 8 92 -17 232 -89 302 -156 l58 -56 -28 -52 c-15 -29 -68 -93 -118
-143 -70 -72 -108 -101 -172 -134 -215 -110 -451 -110 -666 1 -143 73 -335
289 -304 340 19 30 127 113 187 144 61 30 161 62 201 63 18 1 30 -7 40 -25z
m-349 -568 c380 -304 964 -241 1248 136 l59 77 15 -42 c26 -71 36 -164 36
-349 l0 -178 -801 0 -801 0 4 218 c4 191 7 225 27 285 l22 67 59 -78 c32 -43
91 -104 132 -136z m3198 -611 c0 -88 -2 -98 -25 -120 l-24 -25 -1871 0 -1871
0 -24 25 c-23 22 -25 32 -25 120 l0 95 1920 0 1920 0 0 -95z m-1700 -347 c42
-167 120 -359 194 -477 l26 -41 -440 0 -440 0 26 41 c74 118 152 310 194 477
l11 42 209 0 209 0 11 -42z"
        />
        <path
          d="M3189 4201 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M3669 4201 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M4149 4201 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M3189 3801 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M3669 3801 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M4149 3801 c-36 -36 -37 -73 -4 -106 23 -24 31 -25 133 -25 124 0
147 10 158 67 5 27 1 37 -24 63 -30 29 -32 30 -132 30 -99 0 -102 -1 -131 -29z"
        />
        <path
          d="M3108 2680 c-25 -26 -29 -36 -24 -63 13 -68 9 -67 396 -67 387 0 383
-1 396 67 5 27 1 37 -24 63 l-30 30 -342 0 -342 0 -30 -30z"
        />
        <path
          d="M3108 2360 c-25 -26 -29 -36 -24 -63 13 -68 9 -67 396 -67 387 0 383
-1 396 67 5 27 1 37 -24 63 l-30 30 -342 0 -342 0 -30 -30z"
        />
        <path
          d="M3108 2040 c-25 -26 -29 -36 -24 -63 13 -68 9 -67 396 -67 387 0 383
-1 396 67 5 27 1 37 -24 63 l-30 30 -342 0 -342 0 -30 -30z"
        />
      </g>
    </svg>
  );
};
