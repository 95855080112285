import React from "react";
import { motion } from "framer-motion";
import { animation1, animation2, animation3, animation4, container } from "./ContectAnimation";
import { importConfig } from "../../../config/importConfig";

let boxData = [
    {
        image: importConfig.home.msg1,
        heading: "Embed or Share Direct Link",
        text: "Facilitate Seamless Sharing with Direct Link Embedding.",
    },
    {
        image: importConfig.home.msg2,
        heading: "Leverage AI for Autopilot Growth",
        text: "Unleash AI-powered Autopilot Growth Strategies for Your Waitlist.",
    },
    {
        image: importConfig.home.msg3,
        heading: "Boost Your List: Refer with Friends",
        text: "Grow your list effortlessly by referring friends. Spread the word and watch your list expand.",
    },
];

const howItWorkData = [
    {
        image: importConfig.home.content2img1,
        heading: "Create",
        body: "Create your Viral Waitlist using our AI-powered tools.",
    },
    {
        image: importConfig.home.content2img2,
        heading: "Embed / Share",
        body: "Share it easily by direct link or embed it within your product.",
    },
    {
        image: importConfig.home.content2img3,
        heading: "Go Viral",
        body: "Watch it go viral as your audience spreads the word.",
    },
];

const Content1 = () => {
    return (
        <div className="w-full">
            <div className="pt-[60px] px-5 lg:pt-44 lg:px-12 w-full mb-24" id="introduction">
                <motion.p
                    className="text-lg text-home-3 m-auto text-center"
                    variants={animation1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    Introduction
                </motion.p>
                <motion.p
                    className="text-black text-center text-[30px] px-0 md:px-[calc((100vw-880px)/2)] m-auto lg:text-[44px] mt-4 leading-[35px] lg:leading-[48px]"
                    variants={animation1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    Discover Viral Waitlist, your ticket to creating buzz before your product launch. Build a <span className="font-bold text-home-7">Viral Waitlist</span>, share it with your community, and gather early customer insights.
                </motion.p>
            </div>


            <div className="py-[60px] px-5 lg:py-24 lg:px-12 w-full mb-24" id="how-it-works">
                <div>
                    <motion.p
                        className="text-lg text-home-3 m-auto text-center"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        How It Works
                    </motion.p>
                    <motion.p
                        className="text-black text-center text-[30px] w-full lg:w-[55%] m-auto lg:text-[44px] mt-4 leading-[35px] lg:leading-[48px]"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <span className="font-bold text-home-7">Three</span> simple steps
                    </motion.p>
                </div>

                <motion.div
                    className="mt-[60px] grid grid-cols-1 md:grid-cols-3 lg:px-[60px] px-0 gap-8"
                    variants={container}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    {howItWorkData.map((ele, ind) => (
                        <motion.div
                            key={ind}
                            className="px-8 py-10 border rounded-3xl"
                            variants={animation4}
                            viewport={{ once: true }}
                            style={{background: "linear-gradient(135deg, rgba(246,245,255,1) 0%, rgba(255,255,255,1) 0%, rgba(240,212,255,1) 100%)"}}
                        >
                            <img src={ele.image} alt="" className="w-16" />
                            <p className="text-home-2 mt-8 w-full text-[26px]">
                                {ele.heading}
                            </p>
                            <p className="text-home-1 w-full text-[18px] mt-2">
                                {ele.body}
                            </p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>

            <div id="key-features">
                <motion.p
                    className="text-lg text-home-3 m-auto text-center"
                    variants={animation1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    Key Features
                </motion.p>
                <motion.p
                    className="text-black text-center text-[30px] w-full lg:w-[55%] m-auto lg:text-[44px] mt-4 leading-[35px] lg:leading-[48px]"
                    variants={animation1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    Unlock the power of <span className="font-bold text-home-7">Viral Waitlist</span> with these features
                </motion.p>
            </div>

            {/* BOX */}
            <div className="mt-8 lg:mt-[60px] lg:px-[60px] px-5 mb-24 lg:mb-0">
                <div className="grid gap-8 grid-cols-1 px-0 lg:px-[60px] md:grid-cols-3">
                    {boxData.map((data, index) => (
                        <motion.div
                            key={index}
                            className="px-6 py-8 md:p-8 flex flex-col items-center rounded-3xl border-[1px] drop-shadow-md"
                            style={{
                                background:
                                    "linear-gradient(135deg, rgba(246,245,255,1) 0%, rgba(255,255,255,1) 0%, rgba(240,212,255,1) 100%)",
                            }}
                            variants={animation2}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <img src={data.image} alt="" className="w-full drop-shadow-lg"/>
                            <p className="text-home-5 mt-8 md:mt-10 w-full text-[26px]">
                                {data.heading}
                            </p>
                            <p className="text-home-1 w-full text-[18px] mt-2">
                                {data.text}
                            </p>
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* TAGS */}
            <div className="mt-8 hidden lg:flex flex-col items-center justify-center gap-6 mb-24">
                <div className="flex gap-6">
                    {[
                        "Custom Branding",
                        "Performance Tracking",
                        "Email Collection & Verification",
                    ].map((ele, ind) => (
                        <motion.div
                            key={ind}
                            className="px-5 py-4  border-[1px]  rounded-xl text-home-5 text-lg drop-shadow-sm"
                            variants={animation3}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{background: "linear-gradient(135deg, rgba(246,245,255,1) 0%, rgba(255,255,255,1) 0%, rgba(240,212,255,1) 100%)"}}
                        >
                            {ele}
                        </motion.div>
                    ))}
                </div>
                <div className="flex gap-6">
                    {[
                        "Team Collaboration",
                        "Data Export",
                        "Integration & Notifications",
                    ].map((ele, ind) => (
                        <motion.div
                            key={ind}
                            className="px-5 py-4  border-[1px]  rounded-xl text-home-5 text-lg drop-shadow-sm"
                            variants={animation3}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{background: "linear-gradient(135deg, rgba(246,245,255,1) 0%, rgba(255,255,255,1) 0%, rgba(240,212,255,1) 100%)"}}
                        >
                            {ele}
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Content1;
