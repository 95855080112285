import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import Sidebar from './Sidebar';
import tierDetails from '../config/tierDetails.json';
import * as reactSvgs from '../assets/reactSvgs';
import styles from '../styles/Upgrade.module.css';
import config from '../config/config';
import { general } from '../helpers/General';

const defaultCreditsInfo = {
  currentCreditsTierId: 'free',
  history: [{ createdAt: Date.now() }],
  creditsAmount: 100,
  totalCreditsBought: 100,
};

function Upgrade() {
  const [creditsInfo, setCreditsInfo] = useState(defaultCreditsInfo);
  const [plansDuration, setPlansDuration] = useState('monthly')
  const user = general.getUser();
  const navigate = useNavigate();
  const progressBarRef = useRef();

  useEffect(() => {
    if (user.authState == 'loggedOut') {
      navigate('/login');
    } else if (!user.projectId || user.projectId.length === 0) {
      navigate('/account');
    }
  }, [user]);

  useEffect(() => {
    if (user.projectId?.length > 0) {
      (async () => {
        try {
          const response = await axios.get(
            `${config.BACKEND_URL}api/viralwaitlist/projects/${user.projectId}/credits`,
            {
              headers: {
                userId: user.userId,
                token: user.token,
              },
            }
          );

          if (response.data.success === false || !response.data.data) {
            throw new Error(
              response.data.error ||
              "Something wen't wrong, please login again or register your webiste"
            );
          }

          setCreditsInfo(response.data.data);
          if (!!progressBarRef?.current?.style) {
            progressBarRef.current.style.width = `${((response.data.data.totalCreditsBought -
              response.data.data.creditsAmount) /
              response.data.data.totalCreditsBought) *
              100
              }%`;
          }
        } catch (error) {
          toast.error(error.message);
          console.log(error);
        }
      })();
    }
    general.fireTrackingEvent('loaded', 'upgrade_page');
  }, []);

  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles.main}>
        <h1 className={styles.heading}>Upgrade List</h1>

        <div className={styles['current-plan-container-main']}>
          <h3>Your plan</h3>

          <div className={styles['current-plan-container']}>
            <div className={styles['plan-container-left']}>
              <h1>{tierDetails[creditsInfo.currentCreditsTierId]?.name}</h1>
              <p>
                Recieve up to{' '}
                <span>
                  {tierDetails[creditsInfo.currentCreditsTierId]?.submissions}{' '}
                  submissions
                </span>
              </p>
              <div className={styles['plan-active-container']}>
                <p>
                  Active since{' '}
                  <span>
                    {general.formatDate(
                      creditsInfo.history[creditsInfo.history.length - 1]
                        ?.createdAt || Date.now()
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles['plan-container-middle']}></div>
            <div className={styles['plan-container-right']}>
              <p>Current Usage</p>
              <div className={styles['users-waitlist-container']}>
                <p>Users in waitlist</p>
                <h4>
                  {creditsInfo.totalCreditsBought - creditsInfo.creditsAmount}/
                  {creditsInfo.totalCreditsBought}
                </h4>
              </div>
              <div className={styles['progress-bar-container']}>
                <div
                  className={styles['progress-bar']}
                  ref={progressBarRef}
                ></div>
              </div>
            </div>
          </div>

        </div>

        <h2>
          ✨ Upgrade your plan to increase users limit and unlock premium
          features ✨
        </h2>

        {/* ul list  */}
        <ul className={styles['planLifeUp']}>
          <li>
            <button className={`${plansDuration === 'monthly' ? styles['selButtonUp'] : ""}`} onClick={() => setPlansDuration('monthly')}>Monthly</button>
          </li>
          <li><button className={`${plansDuration === 'yearly' ? styles['selButtonUp'] : ""}`} onClick={() => setPlansDuration('yearly')}>Yearly</button></li>
          <li><button className={`${plansDuration === 'life' ? styles['selButtonUp'] : ""}`} onClick={() => setPlansDuration('life')}>Lifetime</button></li>
        </ul>

        {
          (
            <div className={styles['all-plans-container']}>
              {
                Object.keys(tierDetails).map((tier, index) => (
                  <div key={index} className={styles['plan-container']}>
                    <h2>{tierDetails[tier].name}</h2>
                    <div style={{position: "relative", width: "fit-content"}}>
                      <h1>
                        {
                          typeof tierDetails[tier].price === 'number' && <span>$</span>
                        }{' '}
                        {
                          plansDuration === 'yearly' ?
                            (
                              isNaN(tierDetails[tier].price) ? tierDetails[tier].price : tierDetails[tier].yearPrice
                            ) : (
                              plansDuration === 'monthly' ?
                                (tierDetails[tier].price)
                                :
                                (
                                  tierDetails[tier].lifetime
                                )
                            )
                        }
                        {
                          (index !== 3) ? <p style={{
                            fontSize: '16px',
                            display: 'inline-block',
                            marginLeft: '3px'
                          }}>
                            {
                              plansDuration == 'monthly' ? "/mo" : plansDuration == "yearly" ? "/yr" : ""
                            }
                          </p> : ""
                        }
                      </h1>
                      {
                        plansDuration === "life" && ((index == 1 || index == 2)) &&
                        <span style={{fontSize: "12px", border: "1px solid gray", color: "gray", padding: "2px", borderRadius: "5px", position: "absolute", width: "72px", top: "25px", right: "-80px"}}>Limited time</span>
                      }
                    </div>
                    <p>
                      {
                        // (index !== 3) ? general.makeTierDescriptionNumberBold(
                          tierDetails[tier].description
                        // ) : ""
                      }
                    </p>
                    <div className={styles['buy-button-container']}>
                      <Link
                        to={
                          creditsInfo.currentCreditsTierId === tier.toLowerCase() ||
                            tierDetails[tier].name === 'Enterprise' ||
                            tierDetails[tier].name === 'Free'
                            ? 'https://calendly.com/shubham-quest/chat'
                            : `/payment/${tierDetails[tier].name.toLowerCase()}`
                        }
                        target={tierDetails[tier].name === 'Enterprise' ? '_blank' : ""}
                        onClick={() => {
                          general.fireTrackingEvent(
                            `upgrade_button: ${tier}`,
                            'upgrade_page'
                          );
                        }}
                      >
                        <button
                          disabled={
                            creditsInfo.currentCreditsTierId === tier.toLowerCase()
                          }
                        >
                          {
                            creditsInfo.currentCreditsTierId === tier.toLowerCase()
                              ? 'Current'
                              : tierDetails[tier].name
                          }{' '}
                          plan
                        </button>
                      </Link>
                      {/* <p>You'll be charged once for lifetime access</p> */}
                    </div>
                    <h4>{tierDetails[tier].featureCaption}</h4>
                    <div className={styles['features-container']}>
                      {tierDetails[tier].features.map((feature, featureIndex) => (
                        <div
                          key={featureIndex}
                          className={styles['feature-container']}
                        >
                          <reactSvgs.checkIconCircle fill="black" />
                          <p>{feature}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Upgrade;
