import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import config from '../config/config';
import Sidebar from './Sidebar';
import tierDetails from '../config/tierDetails.json';
import * as reactSvgs from '../assets/reactSvgs';
import { general } from '../helpers/General';

import styles from '../styles/StripePayment.module.css';

const defaultUser = {
  email: '',
  name: '',
  country: '',
  postal_code: '',
  city: '',
  state: '',
  line1: '',
  line2: '',
};

export default function StripePayment() {
  const [user, setUser] = useState(defaultUser);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const { creditsTierId } = useParams();
  const { projectId, userId, token } = general.getUser();

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    general.fireTrackingEvent('loaded', 'payment_page');
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setPaymentLoading(true);
    general.fireTrackingEvent('payment_submit', 'payment_page');
    try {
      const { name, email, ...rest } = user;

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
      });

      if (error) {
        throw new Error(error);
      }

      const payload = {
        cardData: {
          name,
          email,
          address: rest
        },
        intentData: {
          amount: tierDetails[creditsTierId].price,
          currency: "usd",
          recurringTimePeriod: "MONTHLY",
          name: creditsTierId,
          paymentMethodId: paymentMethod.id
        }
      }

      const createIntentResponse = await general.makeRequest({
        url: `/projects/${projectId}/users/${userId}/create-payment-intent`,
        method: "POST",
        body: payload,
      });

      if (createIntentResponse.success === false) {
        throw new Error(createIntentResponse.error);
      }

      await general.delay(5000);
      const confirmPaymentResponse = await confirmPayment(createIntentResponse.data.clientSecret);

      if (!confirmPaymentResponse) {
        throw new Error("Payment failed");
      }

      const paymentConfirmResponse = await general.makeRequest({
        url: `/projects/${projectId}/buy-credits`,
        method: "POST",
        body: {
          creditsTierId,
          intentId: createIntentResponse.data.intentId,
        }
      });

      if (paymentConfirmResponse.success === false) {
        throw new Error(paymentConfirmResponse.error);
      }

      toast.success("Payment successfull");
      navigate('/upgrade');
    } catch (error) {
      console.log(error);
      toast.error("Payment failed");
    } finally {
      setPaymentLoading(false);
    }
  };

  const confirmPayment = async (clientSecret) => {
    const response = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
      }
    });

    if (response.error || response.paymentIntent.status === "canceled") {
      throw new Error(response.error || {message: "Payment was cancelled"});
    }

    if (response.paymentIntent.status === "processing") {
      toast.info("Payment processing");
      await general.delay(5000);
      return confirmPayment(clientSecret);
    }

    if (response.paymentIntent.status === "succeeded") return true;

    return false;
  }

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;

    user[name] = value;
    setUser({ ...user });
  };

  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles['container-left']}>
            <h1>Payment</h1>
            <form className={styles.form} onSubmit={handleFormSubmit}>
              <div className={styles['input-container']}>
                <p>Card holder name</p>
                <input
                  className={styles.input}
                  required={true}
                  type="text"
                  placeholder="Full name"
                  name="name"
                  onChange={handleFormInputChange}
                  value={user.name}
                />
              </div>
              <div className={styles['input-container']}>
                <p>Enter billing email</p>
                <input
                  className={styles.input}
                  required={true}
                  type="text"
                  placeholder="Email address"
                  name="email"
                  onChange={handleFormInputChange}
                  value={user.email}
                />
              </div>
              <div className={styles['input-container']}>
                <p>Card number</p>
                <CardNumberElement className={styles.input} />
              </div>
              <div className={styles['input-split-container']}>
                <div className={styles['input-container']}>
                  <p>Expiration date</p>
                  <CardExpiryElement className={styles.input} />
                </div>
                <div className={styles['input-container']}>
                  <p>CVV</p>
                  <CardCvcElement className={styles.input} />
                </div>
              </div>
              <div className={styles['input-split-container']}>
                <div className={styles['input-container']}>
                  <p>Country</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter country"
                    name="country"
                    onChange={handleFormInputChange}
                    value={user.country}
                  />
                </div>
                <div className={styles['input-container']}>
                  <p>Postal Code</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter postal code"
                    name="postal_code"
                    onChange={handleFormInputChange}
                    value={user.postal_code}
                  />
                </div>
              </div>
              <div className={styles['input-split-container']}>
                <div className={styles['input-container']}>
                  <p>City</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    onChange={handleFormInputChange}
                    value={user.city}
                  />
                </div>
                <div className={styles['input-container']}>
                  <p>State</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    onChange={handleFormInputChange}
                    value={user.state}
                  />
                </div>
              </div>
              <div className={styles['input-split-container']}>
                <div className={styles['input-container']}>
                  <p>Address line 1</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter address line 1"
                    name="line1"
                    onChange={handleFormInputChange}
                    value={user.line1}
                  />
                </div>
                <div className={styles['input-container']}>
                <p>Address line 2</p>
                  <input
                    className={styles.input}
                    required={true}
                    type="text"
                    placeholder="Enter address line 2"
                    name="line2"
                    onChange={handleFormInputChange}
                    value={user.line2}
                  />
                </div>
              </div>
              <button
                className={styles['submit-button']}
                type="submit"
                disabled={paymentLoading}
              >
                {paymentLoading ? 'Processing...' : 'Submit'}
              </button>
            </form>
          </div>
          <div className={styles['container-right']}>
            <h1>{tierDetails[creditsTierId].name}</h1>
            <p>{tierDetails[creditsTierId].description}</p>
            <div className={styles['plan-features']}>
              <h4>{tierDetails[creditsTierId].featureCaption}</h4>
              <div className={styles['features-container']}>
                {tierDetails[creditsTierId].features.map((feature, index) => (
                  <div key={index} className={styles['feature-container']}>
                    <reactSvgs.checkIconCircle fill="black" />
                    <p>{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
