export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M4005 5056 c-314 -131 -662 -303 -980 -484 -248 -142 -274 -158 -287
-187 -23 -50 18 -115 72 -115 21 0 106 43 268 136 316 181 749 396 1010 501
10 4 12 -327 10 -1652 l-3 -1657 -175 -68 c-329 -127 -744 -323 -1120 -529
l-145 -79 -5 1641 c-3 906 -9 1650 -14 1660 -22 45 -289 141 -506 181 -338 63
-1029 19 -1552 -99 -158 -35 -183 -43 -210 -64 l-23 -19 -3 -1900 c-1 -1201 1
-1910 7 -1926 6 -14 22 -33 36 -42 26 -17 31 -17 118 4 549 131 1254 188 1576
128 99 -18 287 -71 348 -97 l43 -18 0 -101 0 -100 -1040 0 c-1014 0 -1041 0
-1093 20 -66 25 -121 80 -147 148 -20 51 -20 84 -20 1748 l0 1696 -29 29 c-18
17 -40 29 -56 29 -16 0 -38 -12 -56 -29 l-29 -29 0 -1690 c0 -1090 4 -1708 10
-1743 32 -169 170 -307 340 -339 73 -14 4347 -14 4421 0 169 32 307 170 339
340 6 34 10 649 10 1742 l0 1690 -29 29 c-18 17 -40 29 -56 29 -16 0 -38 -12
-56 -29 l-29 -29 0 -1696 c0 -1669 0 -1696 -20 -1749 -25 -66 -80 -121 -148
-147 -51 -19 -81 -20 -1092 -20 l-1040 0 0 99 0 99 63 21 c241 78 433 111 674
118 358 11 784 -37 1150 -128 144 -36 166 -39 190 -27 15 7 33 24 40 38 10 20
13 411 13 1912 0 1171 -4 1896 -10 1912 -5 14 -19 30 -32 37 -27 15 -180 48
-221 49 -37 0 -87 -49 -87 -85 0 -41 46 -81 104 -89 27 -4 55 -10 62 -13 12
-4 14 -287 14 -1790 0 -1695 -1 -1784 -17 -1779 -130 36 -435 88 -653 112
-228 25 -696 26 -845 1 -124 -21 -341 -75 -456 -113 l-76 -25 -54 21 c-250 99
-476 140 -764 140 -347 0 -774 -46 -1122 -120 -51 -11 -97 -20 -103 -20 -7 0
-10 582 -10 1788 l0 1788 82 17 c326 70 652 112 984 126 361 16 569 -11 837
-110 l57 -21 0 -1672 c0 -1127 4 -1683 11 -1708 10 -37 48 -68 81 -68 7 0 74
34 148 76 520 291 970 505 1375 654 77 28 149 60 160 71 20 19 20 33 20 1805
l0 1786 -28 24 c-16 13 -40 24 -55 23 -15 0 -94 -29 -177 -63z"
        />
        <path
          d="M3580 4121 c-302 -122 -641 -287 -666 -326 -31 -47 13 -125 71 -125
12 0 78 27 146 61 185 91 419 196 549 246 121 46 160 75 160 119 0 35 -51 84
-87 84 -15 0 -93 -27 -173 -59z"
        />
        <path
          d="M3630 3629 c-123 -46 -379 -162 -570 -257 -143 -71 -169 -96 -156
-147 9 -35 45 -65 77 -65 16 0 99 34 186 76 213 102 417 193 536 239 102 39
137 68 137 112 0 31 -51 83 -82 83 -13 0 -70 -19 -128 -41z"
        />
        <path
          d="M1390 3574 c-232 -26 -443 -65 -491 -90 -53 -27 -59 -97 -11 -134 31
-25 46 -25 157 -1 204 45 320 56 575 55 188 -1 264 -5 325 -17 108 -23 131
-22 160 8 44 43 25 122 -32 139 -90 26 -209 38 -418 41 -126 2 -246 1 -265 -1z"
        />
        <path
          d="M3655 3126 c-131 -47 -376 -156 -566 -252 -141 -70 -168 -87 -179
-113 -10 -25 -10 -37 0 -61 29 -70 77 -67 248 19 161 80 440 204 565 251 69
26 91 39 103 61 20 39 18 64 -8 93 -35 39 -63 39 -163 2z"
        />
        <path
          d="M1425 3064 c-16 -2 -77 -9 -135 -15 -116 -12 -339 -53 -383 -71 -55
-22 -67 -88 -24 -135 25 -28 55 -29 172 -4 194 42 330 55 570 55 171 -1 251
-6 310 -18 111 -22 141 -21 170 9 32 31 32 75 3 109 -28 31 -114 51 -281 66
-105 9 -334 11 -402 4z"
        />
        <path
          d="M3605 2596 c-142 -56 -418 -182 -566 -257 -91 -46 -119 -66 -128 -88
-24 -56 12 -113 71 -113 21 0 97 31 203 82 207 100 397 184 527 234 53 21 102
44 108 51 6 7 14 29 17 48 4 29 1 40 -20 61 -37 37 -82 33 -212 -18z"
        />
        <path
          d="M1435 2554 c-161 -17 -317 -39 -414 -59 -148 -31 -179 -57 -159 -133
6 -24 18 -37 41 -47 28 -11 46 -10 145 10 210 44 354 58 572 58 146 0 232 -5
298 -16 133 -24 166 -23 191 9 39 50 20 113 -42 133 -77 25 -224 42 -402 45
-104 2 -208 2 -230 0z"
        />
        <path
          d="M3625 2091 c-241 -92 -675 -300 -705 -338 -27 -35 -21 -78 17 -111
37 -33 53 -28 283 82 248 119 350 164 475 210 136 50 166 86 130 155 -27 53
-65 54 -200 2z"
        />
        <path
          d="M1431 2039 c-203 -13 -490 -63 -538 -94 -52 -35 -48 -112 8 -141 27
-14 40 -13 152 10 175 35 251 45 432 55 177 10 371 0 483 -23 95 -21 95 -21
131 10 39 33 43 83 8 118 -29 28 -155 54 -332 67 -135 9 -169 9 -344 -2z"
        />
        <path
          d="M1365 1523 c-197 -18 -449 -69 -483 -98 -36 -31 -35 -90 3 -122 32
-28 38 -28 150 -4 209 45 312 55 570 56 203 0 269 -4 348 -19 106 -20 147 -14
167 25 15 28 12 75 -7 98 -36 44 -236 71 -508 69 -110 -1 -218 -3 -240 -5z"
        />
      </g>
    </svg>
  );
};
