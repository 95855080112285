import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import templates from '../config/waitlistTemplates';
import config from '../config/config';
import * as imgaes from '../assets/images';

import styles from '../styles/WaitlistTemplate.module.css';
import { general } from '../helpers/General';

export default function WaitlistTemplate({
  getTemplate = () => {},
  projectId,
  user,
}) {
  const [selectedId, setSelectedId] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const overlayRef = useRef();

  useEffect(() => {
    window.addEventListener('click', (event) => {
      if (event.target === overlayRef.current) {
        setShowPopup(false);
      }
    });

    return () => window.removeEventListener('click', () => {});
  }, []);

  const handleSelectTemplate = (id, name) => {
    general.fireTrackingEvent(`template_button: ${name}`, "waitlist_template");
    if (selectedId === id) {
      setSelectedId(-1);
      getTemplate({});
    } else if (id === 1) {
      setShowPopup(true);
    } else {
      setSelectedId(id);
      getTemplate(templates.filter((template) => template.id === id)[0]);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (formLoading) return;

    try {
      setFormLoading(true);
      const answer = event.target.answer.value;
      const response = await axios.post(
        `${config.BACKEND_URL}api/viralwaitlist/projects/${projectId}/generate-waitlist`,
        {
          message: 'Product/service: ' + answer,
        },
        {
          headers: {
            userId: user.userId,
            token: user.token,
          },
        }
      );

      if (response.data.success === false) {
        throw new Error("Sorry, couldn't generate waitlist using AI");
      }

      getTemplate(response.data.data);
      setSelectedId(1);
      toast.success('Successfully generated waitlist using AI');
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setFormLoading(false);
      setShowPopup(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Try the templates</h2>
      <div className={styles['templates-container']}>
        {templates.map((template, index) => (
          <div
            key={index}
            className={template.name ==="Generate with AI" ? styles['button-85'] : styles['template-container']}
            data-selected={(selectedId === template.id) + ''}
            onClick={() => handleSelectTemplate(template.id, template.name)}
          >
            <template.image
              fill={selectedId === template.id ? '#9405e4' : 'black'}
            />
            <p>{template.name}</p>
            {false && <button>Apply</button>}
          </div>
        ))}
      </div>
      {showPopup && (
        <div className={styles.overlay} ref={overlayRef}>
          <div className={styles.modal}>
            <form onSubmit={handleFormSubmit}>
              <p>Why are you creating this list and what for ?</p>
              <input
                type="text"
                name="answer"
                placeholder="Enter your answer"
                required={true}
              />
              <button type="submit" disabled={formLoading}>
                {formLoading ? 'Generating...' : 'Generate template '}
              </button>
            </form>
            <button className={styles['close-btn']} onClick={handleClosePopup}>
              <img src={imgaes.closeIcon} alt="close" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
