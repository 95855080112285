export default ({ width = '30px', height = '30px', fill = '#000000' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1362 5104 c-111 -30 -208 -118 -254 -232 l-23 -57 0 -2255 0 -2255
23 -57 c47 -116 143 -203 258 -233 88 -23 2300 -23 2388 0 115 30 211 117 258
233 l23 57 0 2255 0 2255 -23 57 c-47 116 -143 203 -258 233 -85 22 -2310 21
-2392 -1z m2398 -161 c31 -17 65 -45 84 -71 l31 -44 3 -154 3 -154 -1321 0
-1321 0 3 154 3 154 31 44 c19 26 53 54 84 71 l52 27 1148 0 1148 0 52 -27z
m120 -2233 l0 -1660 -1066 0 c-1073 0 -1094 -1 -1116 -34 -10 -14 -10 -68 0
-82 22 -33 43 -34 1117 -34 l1066 0 -3 -304 -3 -304 -31 -44 c-19 -26 -53 -54
-84 -71 l-52 -27 -1148 0 -1148 0 -52 28 c-31 16 -65 44 -84 70 l-31 44 -3
304 -3 304 116 0 c146 0 175 12 175 75 0 63 -29 75 -174 75 l-116 0 0 1660 0
1660 1320 0 1320 0 0 -1660z"
        />
        <path
          d="M2225 4808 c-28 -16 -35 -28 -35 -65 0 -53 55 -87 102 -62 31 16 38
28 38 64 0 36 -8 48 -37 64 -27 14 -42 14 -68 -1z"
        />
        <path
          d="M2525 4808 c-28 -16 -35 -28 -35 -63 0 -66 25 -75 220 -75 195 0 220
9 220 75 0 67 -25 75 -222 75 -102 0 -170 -5 -183 -12z"
        />
        <path
          d="M2460 723 c-31 -17 -65 -45 -84 -71 -29 -41 -31 -50 -31 -127 0 -77
2 -86 31 -127 41 -56 119 -98 184 -98 65 0 143 42 184 98 29 41 31 50 31 127
0 77 -2 86 -31 127 -41 56 -119 98 -184 98 -33 0 -64 -9 -100 -27z m133 -134
c29 -16 37 -28 37 -64 0 -36 -7 -48 -38 -64 -47 -25 -102 9 -102 62 0 37 7 49
35 65 26 15 41 15 68 1z"
        />
      </g>
    </svg>
  );
};
