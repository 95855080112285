import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from './pages/Router';
import * as images from './assets/images';

function App() {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={({ type }) =>
          type === 'error' ? (
            <img src={images.closeIcon} alt="error" />
          ) : type === 'warning' ? (
            <img src={images.toolTipIcon} alt="warning" />
          ) : type === 'info' ? (
            <img src={images.infoIcon} alt='info'/>
          ) : (
            <img src={images.tickMark} alt="success" />
          )
        }
      />
      <Router />
    </div>
  );
}

export default App;
