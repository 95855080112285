import React from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import * as svgs from '../assets/svgs';
import * as images from '../assets/images';
import * as gifs from '../assets/gifs';
import * as reactSvgs from '../assets/reactSvgs';

import styles from '../styles/LandingPage.module.css';

function LandingPage() {
  return (
    <div>
      <nav className={styles.nav}>
        <div>
          <img
            className={styles['nav-logo']}
            src={svgs.viralWaitlistLogo}
            alt="viral waitlist logo"
          />
        </div>
        <div className={styles['links-container']}>
          <Link to="/pricing">Pricing</Link>
          <a href="#demo">Demo</a>
          <Link to="/login">
            <button>Log in</button>
          </Link>
        </div>
      </nav>
      <section className={styles.hero}>
        <div className={styles['hero-left']}>
          <h1>
            Build a Buzz with a <span>Viral Waitlist</span>
          </h1>
          <p>
            Jumpstart your product's launch with our gamified pre-launch viral
            waitlists. Gather early enthusiasts, save launch time, and amplify
            your sign-ups.
          </p>
          <div className={styles['hero-button-container']}>
            <Link to="/create-waitlist">
              <button>Get Started</button>
            </Link>
            <Link to="https://calendly.com/richexplorer/chat" target="_blank">
              <button>Want more? Lets talk!</button>
            </Link>
          </div>
        </div>
        <div className={styles['hero-right']}>
          <img src={images.waitlistFormFull} alt="waitlist form" />
        </div>
      </section>
      <section className={styles['chart-section']}>
        <div className={styles['chart-left']}>
          <img src={images.overviewChart} alt="activity-chart" />
        </div>
        <div className={styles['chart-right']}>
          <h4>Stunning Waitlist Forms for Your Next Launch</h4>
          <h1>
            Crafted to <span>Complement</span>
          </h1>
          <p>
            Seamless forms that blend perfectly with your website's aesthetic.
          </p>
          <h1>
            Designed for <span>Founders, Marketers, & Developers</span>
          </h1>
          <p>
            Robust yet simple, gather any information you need without any
            cumbersome integrations. Your users will eagerly jump on board.
          </p>
        </div>
      </section>
      <section className={styles['referral-section']}>
        <div className={styles['referral-left']}>
          <h4>Turbocharge Your Growth with Automation</h4>
          <h1>
            Viral <span>Referrals</span>
          </h1>
          <p>
            Empower your users to be your brand ambassadors. As they refer
            friends and climb the waitlist, you build a community, even before
            your product's launch.
          </p>
          <h1>
            Marketing <span>Autopilot</span>
          </h1>
          <p>
            Focus on perfecting your MVP while your users spread the word
            organically.
          </p>
        </div>
        <div className={styles['referral-right']}>
          <img src={images.waitlistReferral} alt="referral-form" />
        </div>
      </section>
      <section className={styles['submission-section']}>
        <h4>Superior Usability</h4>
        <h1>Data Mastery</h1>
        <p>
          Handle millions of users effortlessly. Delve deep into insights with
          powerful filters and sorters.
        </p>
        <h1>Effortless Integration & Management</h1>
        <p>
          No need for coding or database hassles. Integrate the waitlist on your
          website within minutes and manage data with ease.
        </p>
        <img src={images.submissionsTable} alt="submissions table" />
      </section>
      <section className={styles['demo-section']} id="demo">
        <h4>Simplicity at its Best</h4>
        <h1>
          A no-code platform that's <span>intuitive and quick</span>.
        </h1>
        <p>
          Whether you're a seasoned developer or a beginner, our platform caters
          to all.
        </p>
        <img src={gifs.viralWaitlistDemo} alt="application demo" />
      </section>
      <section className={styles['integration-section']}>
        <h4>Ready to Amplify Your Launch?</h4>
        <h1>
          Integrate waitlist from on your website in <span>just 2 minutes</span>
        </h1>
        <Link to="/login">Get started</Link>
        <div className={styles['integration-properties-container']}>
          <div className={styles['integration-property']}>
            <reactSvgs.checkIconCircle />
            <p>Quick Integration: Live in just 2 minutes.</p>
          </div>
          <div className={styles['integration-property']}>
            <reactSvgs.checkIconCircle />
            <p>Zero Database Woes.</p>
          </div>
          <div className={styles['integration-property']}>
            <reactSvgs.checkIconCircle />
            <p>No Coding Needed.</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default LandingPage;
