import mixpanel from 'mixpanel-browser';
import config from '../config/config';
import { AUTH_STATUS } from './enums';

class General {
  user = {
    authState: AUTH_STATUS.LOADING,
    userId: '',
    token: '',
    projectId: '',
  };

  project = {
    name: '',
    image: '',
    website: '',
    description: '',
  };

  constructor() {
    mixpanel.init('fe609c877c6101a93fa2bdb2bc53dd6c');
  }

  fireTrackingEvent(eventName, pageName) {
    if (pageName) {
      mixpanel.track(`ViralWaitlist-${pageName}_${eventName}`);
    } else {
      mixpanel.track(`ViralWaitlist-${eventName}`);
    }
  }

  setUser({ userId, token, projectId }) {
    this.user.authState = AUTH_STATUS.LOGGED_IN;
    this.user.userId = userId;
    this.user.token = token;
    this.user.projectId = projectId;
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }

  getUser() {
    return this.user;
  }

  logout() {
    this.user = {
      authState: AUTH_STATUS.LOGGED_OUT,
      userId: '',
      token: '',
      projectId: '',
    };

    this.project = {
      name: '',
      image: '',
      website: '',
      description: '',
    };

    localStorage.clear();
  }

  getProject() {
    return this.project;
  }

  setProject({ name, image, website, description }) {
    this.project.name = name;
    this.project.image = image;
    this.project.website = website;
    this.project.description = description;
    localStorage.setItem('project', JSON.stringify(this.project));
    return this.project;
  }

  async verifyLogin() {
    try {
      if (this.user.authState === AUTH_STATUS.LOGGED_IN) return;

      const savedUser = localStorage.getItem('user');
      const savedProject = JSON.parse(localStorage.getItem('project')) || null;

      if (!savedUser) {
        throw new Error('User not logged in');
      }

      const retrivedUser = JSON.parse(savedUser);
      const tokenPayload = JSON.parse(atob(retrivedUser.token.split('.')[1]));

      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (tokenPayload.exp < currentTimestamp) {
        throw new Error('Token has expired.');
      }

      this.setUser({ ...retrivedUser });
      if (savedProject) {
        this.setProject({ ...savedProject });
      }
    } catch (error) {
      console.log(error);
      this.logout();
    }
  }

  async makeRequest({ url, method, headers = {}, query = {}, body = {} }) {
    try {
      headers.userId = this.user.userId;
      headers.token = this.user.token;
      headers['Content-Type'] = 'application/json';

      if (query) {
        const queryString = new URLSearchParams(query).toString();
        url = `${url}?${queryString}`;
      }

      const canHaveBody = ['POST', 'PUT', 'PATCH'].includes(method);

      const response = await fetch(
        `${config.BACKEND_URL}api/viralwaitlist${url}`,
        {
          method,
          headers,
          ...(canHaveBody && { body: JSON.stringify(body) }),
        }
      );

      const data = await response.json();

      if (data.success === false && data.loginAgain === true) {
        let prevUrl = window.location.pathname + window.location.search;
        localStorage.setItem('prevUrl', prevUrl);

        window.location = '/login';
      }

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async compressImage(file, { quality = 1, type = file.type }) {
    const imageBitmap = await createImageBitmap(file);

    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);

    return await new Promise((resolve) =>
      canvas.toBlob(resolve, type, quality)
    );
  }

  makeTierDescriptionNumberBold(text = '') {
    return text
      .split(' ')
      .map((word, wordIndex) =>
        isNaN(word) == false || word[word.length - 1] === 'K' ? (
          <strong key={wordIndex}>{word + ' '}</strong>
        ) : (
          word + ' '
        )
      );
  }

  formatDate(unformatted) {
    if (unformatted === '' || !unformatted) return unformatted;

    const date = new Date(unformatted);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(
      date
    );
    return formattedDate;
  }

  async getWebsiteMetadata(url = '') {
    try {
      const response = await fetch(url);
      const html = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      const title = doc.querySelector('title').textContent;
      const descriptionMeta = doc.querySelector('meta[name="description"]');
      const description = descriptionMeta
        ? descriptionMeta.getAttribute('content')
        : '';

      const faviconLink =
        doc.querySelector('link[rel="icon"]') ||
        doc.querySelector('link[rel="shortcut icon"]');
      const faviconUrl = faviconLink
        ? url.substring(0, url.length - 1) + faviconLink.getAttribute('href')
        : null;

      return { title, description, faviconUrl };
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  isValidName(text) {
    const asciiValue = text.charCodeAt(0);

    if (
      (asciiValue >= 65 && asciiValue <= 90) ||
      (asciiValue >= 97 && asciiValue <= 122)
    ) {
      return true;
    }
    return false;
  }

  delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}

export const general = new General();
