import * as reactSvgs from '../assets/reactSvgs';

export default [
  {
    id: 1,
    image: reactSvgs.lightBulbIcon,
    name: 'Generate with AI',
  },
  {
    id: 2,
    image: reactSvgs.rocketIcon,
    name: 'Tech Startup Product',
    title: "Experience Tomorrow's Tech, Today!",
    description:
      "Join our exclusive waitlist for [Product Name], the revolutionary [product specialty, e.g., 'smart home device']. Grab your spot and be the first to live in the future.",
    questions: [
      'What current products or brands do you use in this category?',
      'How often do you purchase or upgrade similar products?',
      'Are you interested in exclusive beta testing?',
    ],
  },
  {
    id: 3,
    image: reactSvgs.tshirtIcon,
    name: 'Fashion Line Launch',
    title: 'Front Row to the Future of Fashion!',
    description:
      'Get VIP access to our upcoming [Season] Collection by [Brand Name]. Stay a step ahead in the fashion game.',
    questions: [
      "What's your preferred fashion style (e.g., Casual, Formal, Boho, etc.)?",
      'Which fashion brands do you love most?',
      "What's the most important factor for you in clothing: Comfort, Style, or Brand?",
    ],
  },
  {
    id: 4,
    image: reactSvgs.mobilePhoneIcon,
    name: 'Mobile App Launch',
    title: 'Unlock the App Everyone Will Talk About!',
    description:
      "Eager to redefine your [activity e.g., 'fitness goals']? Get on our waitlist for [App Name] and redefine your daily routine.",
    questions: [
      "What apps do you currently use for [purpose e.g., 'tracking workouts']?",
      'How often do you try out new apps?',
      "What features matter most to you in a [related type, e.g., 'fitness'] app?",
    ],
  },
  {
    id: 5,
    image: reactSvgs.openSurpriseBox,
    name: 'Subscription Box Service',
    title: 'Exclusive Tastes Await You!',
    description:
      "Sign up and be the first to savor our handpicked [theme e.g., 'gourmet delights'] every month. Delight, surprise, repeat!",
    questions: [
      'Which subscription services are you currently part of?',
      'What items would you love to see in our subscription box?',
      'How often do you prefer to receive a subscription box?',
    ],
  },
  {
    id: 6,
    image: reactSvgs.teachingIcon,
    name: 'Online Course or Workshop',
    title: "Master [Skill, e.g., 'Photography'] with Early Access!",
    description:
      'Why wait? Join our waitlist and begin your journey to mastering [skill] with expert guidance.',
    questions: [
      "What's your current proficiency in [skill]?",
      'What topics are you most eager to learn about?',
      'Do you prefer live sessions, recorded sessions, or a mix of both?',
    ],
  },
  {
    id: 7,
    image: reactSvgs.openBookIcon,
    name: 'Book or Novel Release',
    title: 'The Next Bestseller: Read it Before the Rest!',
    description:
      "Secure your exclusive copy of [Author]'s upcoming book. Dive into a world no one else has seen yet.",
    questions: [
      'What genres do you most enjoy?',
      "Have you read any of [Author]'s previous works?",
      'Do you prefer digital, print, or audio format?',
    ],
  },
  {
    id: 8,
    image: reactSvgs.speechIcon,
    name: 'Event or Conference',
    title: 'Unlock the Ultimate Event Experience!',
    description:
      'Be the first to secure a spot at [Event Name]. Connect, network, and transform with industry leaders.',
    questions: [
      'Which speakers or topics are you most interested in?',
      'Have you attended [Event Name] before?',
      "What's your primary goal for attending: Learning, Networking, or Both?",
    ],
  },
];
