import React from 'react';
import { Navigate } from 'react-router-dom';
import { AUTH_STATUS } from '../helpers/enums';
import { general } from '../helpers/General';

function SafeRoute({ Component, children }) {
  general.verifyLogin();
  const user = general.getUser();

  if (window.location.pathname == '/invite') {
    let prevUrl = window.location.pathname + window.location.search;
    localStorage.setItem('prevUrl', prevUrl);
  }

  if (user.authState === AUTH_STATUS.LOGGED_IN) {
    return children || <Component />;
  } else if (user.authState === AUTH_STATUS.LOGGED_OUT) {
    return <Navigate to="/login" />;
  } else {
    return <div>Loading...</div>;
  }
}

export default SafeRoute;
