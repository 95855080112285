import React, { useState } from "react";
import { animation1, animation4, container } from "./ContectAnimation";
import { motion } from "framer-motion";
import { importConfig } from "../../../config/importConfig";

const QandA = ({ question, answer, state }) => {
    const [open, setOpen] = useState(state);

    return (
        <motion.div
            className={`px-6 py-7 rounded-3xl ${
                open && "bg-home-3"
            } duration-150 mt-3`}
            variants={animation1}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
        >
            <div
                className="text-xl text-home-2 flex justify-between gap-3 cursor-pointer"
                onClick={() => setOpen(!open)}
            >
                <p>{question}</p>
                <img
                    src={importConfig.home.plusIcon}
                    className={`w-5 h-5 ${open && "rotate-45"} duration-150`}
                    alt=""
                />
            </div>
            <p
                className={`text-lg text-home-1 mt-3 ${
                    open ? "block" : "hidden"
                } delay-300`}
            >
                {answer}
            </p>
        </motion.div>
    );
};




const FAQData = [
    {
        question: "How does Viral Waitlist handle MVP creation?",
        answer: "We prioritize rapid, agile development for quality and scalability.",
        state: true,
    },
    {
        question: "Ensuring our waitlist aligns with your vision?",
        answer: "We collaborate, update regularly, and adapt to your feedback.",
    },
    {
        question: "Which technologies does Viral Waitlist specialize in?",
        answer: "Proficient in diverse, modern technologies for various development needs.",
    },
    {
        question:
            "Handling changes during Viral Waitlist development?",
        answer: "Certainly. Our agile approach accommodates changes at any development stage.",
    },
    {
        question:
            "Post-launch support for Viral Waitlist MVPs?",
        answer: "We offer post-launch support for bug fixes, enhancements, and scalability advice to evolve your Viral Waitlist as needed.",
    },
];

const Content2 = () => {
    return (
        <div className="w-full">

            <img src={importConfig.home.stick} alt="" id="faqs"/>

            <div className="py-[60px] px-5 lg:py-20 lg:px-12 w-full">
                <div>
                    <motion.p
                        className="text-lg text-home-3 m-auto text-center"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        FAQs
                    </motion.p>
                    <motion.p
                        className="text-black text-center text-[30px] w-full lg:w-[55%] m-auto lg:text-[44px] mt-4 leading-[35px] lg:leading-[48px]"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        We’ve got the answers
                    </motion.p>
                </div>
                <div className="mt-[60px] px-0 md:px-[calc((100vw-880px)/2)] ">
                    {FAQData.map((faq, ind) => (
                        <div key={ind}>
                            <QandA
                                question={faq.question}
                                answer={faq.answer}
                                state={!!faq.state ? faq.state : false}
                            />
                        </div>
                    ))}
                </div>
                <div className="mt-[60px]">
                    <p className="text-home-1 text-lg text-left lg:text-center w-full">
                        Still have more questions?{" "}
                        <span className="text-home-2">Lets get in touch!</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Content2;
