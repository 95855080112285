import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useClickAway } from 'react-use';
import { toast } from 'react-toastify';
import axios from 'axios';

import Sidebar from './Sidebar';
import config from '../config/config';
import SingleLineChart from './LineChart';
import Crisp from '../sdks/Crisp';
import styles from '../styles/Overview.module.css';
import * as reactSvgs from '../assets/reactSvgs';
import * as svgs from '../assets/svgs';
import * as images from '../assets/images';
import { general } from '../helpers/General';

const defaultSummary = { questStats: [], answers: [{ answers: [] }] };

function Overview() {
  const [searchParams] = useSearchParams();

  const user = general.getUser();
  const waitlistId = searchParams.get('waitlistId');

  const waitlistUrl = `${window.location.origin}/invite?waitlistId=${waitlistId}&projectId=${user.projectId}`;

  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [embedPopupOpen, setEmbedPopupOpen] = useState(false);
  const [shortURL, setShortURL] = useState(waitlistUrl);
  const [SubmissionData, setSubmissionData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const embedUrl = `<iframe src="${window.location.origin}/embed/login?waitlistId=${waitlistId}&projectId=${user.projectId}&backgroundColor=black&foregroundColor=grey&headingColor=black&fontColor=silver&inputBackground=black&inputFontColor=silver&buttonBackground=silver&buttonFontColor=black" width="700" height="700" frameBorder="0" allowFullScreen></iframe>`;

  const [summary, setSummary] = useState(defaultSummary);
  const [selectedMenu, setSelectedMenu] = useState("Iframe");
  const shareContainerRef = useRef();
  const embedContainerRef = useRef();
  const navigate = useNavigate();

  const navigationBar = {
    "Iframe": {
      heading: "",
      desc: "",
      icon: svgs.embed
    },
    "React SDK": {
      heading: "Waitlist Form, React Ready",
      desc: "Effortlessly embed our waitlist form in your React app using our React SDK. Copy, paste, and start collecting sign-ups seamlessly.",
      qId: "q-7d72f90a-9280-46d8-8c0c-c94f2484d7d4",
      ecId: "ec-2b02df2a-5896-4314-9e2b-eb3dcf2717fb",
      icon: svgs.react
    },
    "React Native": {
      heading: "Waitlist in React Native",
      desc: "Swiftly integrate our waitlist form into your React Native app. Enhance user engagement and capture leads effortlessly.",
      qId: "q-e974b86a-5ca5-4f3a-bb0f-65975cd4f274",
      ecId: "ec-35fd7844-4ef1-4ae6-af5e-8be5f0f6fc1e",
      icon: svgs.reactNative
    },
    "Flutter": {
      heading: "Flutter-Ready Waitlist",
      desc: "Embed our waitlist form seamlessly into your Flutter app. Streamline the sign-up process with ease.",
      qId: "q-a023424a-3122-4359-8b93-653dab496656",
      ecId: "ec-0d08b518-aa69-43be-81d9-c4bb9af79e29",
      icon: svgs.flutter
    },
    "Javascript": {
      heading: "Waitlist for Every Website",
      desc: "Integrate our waitlist form into any website using JavaScript. Boost user engagement and gather leads effortlessly.",
      qId: "q-59f1c3b4-2726-4068-baa5-bfb4086b2a22",
      ecId: "ec-231967dc-6668-41f7-be7d-698bee7ddb04",
      icon: svgs.js
    },
  }

  const closeSharePopup = () => {
    setSharePopupOpen(false);
  };

  const closeEmbedPopup = () => {
    setEmbedPopupOpen(false);
  };

  useClickAway(shareContainerRef, closeSharePopup);
  useClickAway(embedContainerRef, closeEmbedPopup);

  useEffect(() => {
    general.fireTrackingEvent('loaded', 'overview_page');
  }, []);

  useEffect(() => {
    if (!user.projectId || user.projectId.length === 0) {
      navigate('/account');
    }

    setLoading(true);
    setSummary(defaultSummary);
    setAnswers([]);
    axios
      .get(`${config.BACKEND_URL}api/viralwaitlist/waitlists/summary`, {
        headers: {
          token: user.token,
        },
        params: {
          userId: user.userId,
          projectId: user.projectId,
          waitlistId: waitlistId,
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          throw new Error(res.data.error);
        }
        setSummary(res.data.summary);

        if (!res?.data?.summary?.answers) {
          return;
        }

        const answers = [];
        const questions = new Set();

        for (let user of res.data.summary.answers) {
          if (!user?.answers) {
            continue;
          }

          const answerObj = {
            userName: user.name,
          };

          if (user.imageUrl) {
            if (user.imageUrl.includes('http')) {
              answerObj.imageUrl = user.imageUrl;
            } else {
              answerObj.imageUrl = config.IPFS_PREFIX + user.imageUrl;
            }
          }

          for (let answer of user.answers) {
            answerObj[answer.question] = answer.userAnswer[0];
            questions.add(answer.question);
          }
          answers.push(answerObj);
        }

        setAnswers(answers);
        setQuestions(Array.from(questions));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [waitlistId]);

  useEffect(() => {
    const setSubmissionAndViewData = () => {
      const sortedChartData = [...summary.questStats].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      const updatedSubmissionData = [
        {
          name: 'Submissions',
          count: 0,
          day: '0',
        },
      ];
      const updatedViewData = [
        {
          name: 'Views',
          count: 0,
          day: '0',
        },
      ];

      for (const stat of sortedChartData) {
        const claimStat = {
          count: stat.claimCount,
          date: stat.date.slice(5, 10),
        };

        updatedSubmissionData.push(claimStat);

        const viewStat = {
          count: stat.viewCount,
          date: stat.date.slice(5, 10),
        };
        updatedViewData.push(viewStat);
      }

      setSubmissionData(updatedSubmissionData);
      setViewData(updatedViewData);
    };

    setSubmissionAndViewData();
  }, [summary.questStats]);

  const showSharePopup = async () => {
    general.fireTrackingEvent('share_button', 'overview_page');
    setSharePopupOpen(true);
    const shortUrl = await shortenURL(waitlistUrl);
    setShortURL(shortUrl);
  };

  const showEmbedPopup = async () => {
    general.fireTrackingEvent('embed_button', 'overview_page');
    setEmbedPopupOpen(true);
  };

  const toggleCheckIconShare = () => {
    general.fireTrackingEvent(`share_waitlist_button: copy`, 'overview_page');
    navigator.clipboard.writeText(shortURL);
    setShowCheckIcon(true);
    setTimeout(() => setShowCheckIcon(false), 500);
  };

  const toggleCheckIconEmbed = () => {
    navigator.clipboard.writeText(embedUrl);
    setShowCheckIcon(true);
    setTimeout(() => setShowCheckIcon(false), 500);
  };

  const shortenURL = async (url) => {
    try {
      const response = await axios.get(
        `https://api.shrtco.de/v2/shorten?url=${encodeURIComponent(url)}`
      );

      if (response?.data?.result?.full_short_link) {
        return response.data.result.full_short_link;
      }

      return url;
    } catch (error) {
      console.log(error);
      return url;
    }
  };

  const handleContactClick = () => {
    Crisp.chat.open();
  };

  const handleShareClick = (name) => {
    general.fireTrackingEvent(
      `share_waitlist_button: ${name}`,
      'overview_page'
    );
  };

  const handleShowInterestClick = async() => {
    let { qId, ecId } = navigationBar[selectedMenu]
    setApiLoading(true)

    axios(`${config.BACKEND_URL}api/viralwaitlist/users/${user.userId}`, {
      headers: {
        token: user.token,
        userId: user.userId
      },
      params: {
        userId: user.userId,
      },
    }).then((res) => {
      let data = res.data
      if (!data?.success) {
        setApiLoading(false)
        toast.error("Internal Server Error")
      }

      let {emails} = data.data
      
      if (!!emails?.length) {
        const actions = [{
          criteriaId: ecId,
          answer: [emails[0]]
        }]

        axios.post(
          `${config.BACKEND_URL}api/viralwaitlist/waitlists/verify`,
          {
            criterias: actions,
            projectId: config.VIRAL_WAITLIST_ENTITY_ID,
          },
          {
            headers: {
              token: user.token,
            },
            params: {
              userId: user.userId,
              waitlistId: qId,
            },
          }
        ).then((e) => {
          setApiLoading(false)
          e?.data?.success ? toast.success("Thank you for showing interest") : toast.error("Internal server error")
        })
      }
    })

  }



  return (
    <div className={`${styles['main-container']} overview-details-page`}>
      <Sidebar />
      <div className={styles.main}>
        <div className={styles['heading-container']}>
          <h1 className={styles.heading}>Overview</h1>
          <div className={styles['heading-right']}>
            <Link
              to={'/create-waitlist?waitlistId=' + waitlistId}
              className={styles['heading-right']}
              onClick={() => {
                general.fireTrackingEvent('edit_button', 'overview_page');
              }}
            >
              <button>Edit</button>
            </Link>
            <div className={styles['heading-right']}>
              <button onClick={showSharePopup}>Share</button>
            </div>
            <div className={styles['heading-right']}>
              <button onClick={showEmbedPopup}>Embed</button>
            </div>
          </div>
          {sharePopupOpen && (
            <div className={styles.overlay}>
              <div
                className={styles['share-container']}
                ref={shareContainerRef}
              >
                <h1>Share waitlist with others</h1>
                <p className={styles['share-caption']}>Copy waitlist link</p>
                <div className={styles['share-link-container']}>
                  <p>{shortURL}</p>
                  <button onClick={toggleCheckIconShare}>
                    {showCheckIcon ? (
                      <reactSvgs.checkIcon />
                    ) : (
                      <reactSvgs.copyIcon />
                    )}
                  </button>
                </div>

                <div className={styles['social-share-container-main']}>
                  <p className={styles['share-caption']}>
                    Share on social media
                  </p>
                  <div className={styles['social-share-container']}>
                    <div className={styles['share-icon']}>
                      <FacebookShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('facebook');
                        }}
                      >
                        <FacebookIcon size={30} round={true} />
                      </FacebookShareButton>
                    </div>
                    <div className={styles['share-icon']}>
                      <WhatsappShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('whatsapp');
                        }}
                      >
                        <WhatsappIcon size={30} round={true} />
                      </WhatsappShareButton>
                    </div>
                    <div className={styles['share-icon']}>
                      <LinkedinShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('linkedin');
                        }}
                      >
                        <LinkedinIcon size={30} round={true} />
                      </LinkedinShareButton>
                    </div>
                    <div className={styles['share-icon']}>
                      <TwitterShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('twitter');
                        }}
                      >
                        <TwitterIcon size={30} round={true} />
                      </TwitterShareButton>
                    </div>
                    <div className={styles['share-icon']}>
                      <TelegramShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('telegram');
                        }}
                      >
                        <TelegramIcon size={30} round={true} />
                      </TelegramShareButton>
                    </div>
                    <div className={styles['share-icon']}>
                      <EmailShareButton
                        url={shortURL}
                        onClick={() => {
                          handleShareClick('email');
                        }}
                      >
                        <EmailIcon size={30} round={true} />
                      </EmailShareButton>
                    </div>
                  </div>
                </div>
                <button
                  className={styles['close-btn']}
                  onClick={closeSharePopup}
                >
                  <img src={images.closeIcon} alt="" />
                </button>
              </div>
            </div>
          )}
          {embedPopupOpen && (
            <div className={styles.overlay}>
              <div className={styles['share-container']} ref={embedContainerRef}>
                <div className={styles['menu-container-wrapper']}>
                  <div className={styles['menu-container']}>
                    {
                      Object.keys(navigationBar)?.map((item, i) => (
                        <p onClick={() => setSelectedMenu(item)} style={{borderColor: selectedMenu == item ? "#8019FD" : "transparent"}}>{item}</p>
                      ))
                    }
                    {
                      Object.keys(navigationBar)?.map((item, i) => (
                        <img src={navigationBar[item].icon} onClick={() => setSelectedMenu(item)} style={{borderColor: selectedMenu == item ? "#8019FD" : "transparent"}}/>
                      ))
                    }
                  </div>
                </div>
                {
                  selectedMenu == "Iframe" ?
                  <div
                    className={styles['iframe-container']}
                  >
                    <h1>Use embed on your website</h1>
                    <p className={styles['share-caption']}>
                      Copy embed url and and paste this into your html file to show
                      your waitlist form on your website
                    </p>
                    <div className={styles['share-link-container']}>
                      <p>{embedUrl}</p>
                      <button onClick={toggleCheckIconEmbed}>
                        {showCheckIcon ? (
                          <reactSvgs.checkIcon />
                        ) : (
                          <reactSvgs.copyIcon />
                        )}
                      </button>
                    </div>
                    <button
                      className={styles['close-btn']}
                      onClick={closeEmbedPopup}
                    >
                      <img src={images.closeIcon} alt="" />
                    </button>
                  </div>
                  :
                  <div
                    className={styles['iframe-container']}
                  >
                    <h1>{navigationBar[selectedMenu]["heading"]}</h1>
                    <p className={styles['share-caption']}>
                      {navigationBar[selectedMenu]["desc"]}
                    </p>
                    <button
                      className={styles['contact-button']}
                      onClick={handleShowInterestClick}
                    >
                      I want this
                    </button>
                    <button
                      className={styles['close-btn']}
                      onClick={closeEmbedPopup}
                    >
                      <img src={images.closeIcon} alt="" />
                    </button>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
        <div className={styles['chart-container']}>
          <div className={styles['line-chart']}>
            <p>Number of Waitlist Viewers</p>
            <SingleLineChart data={viewData} />
          </div>
          <div className={styles['line-chart']}>
            <p>Number of Waitlist Submissions</p>
            <SingleLineChart data={SubmissionData} />
          </div>
        </div>
        <div className={styles['table-container']}>
          <h1 className={styles.heading2}>User inputs</h1>
          <table>
            <thead>
              <tr>
                <th>Position</th>
                <th>Name</th>
                {questions.map((question, index) => (
                  <th key={index}>{question}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {answers.map((answer, index) => (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{answer.userName}</td>
                  {questions.map((question, qindex) => (
                    <td key={qindex}>{answer[question] || "no-data"}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && answers.length === 0 && (
            <div className={styles['no-answer-container']}>
              <h2>No user inputs recieved</h2>
            </div>
          )}
          {loading && (
            <div className={styles['loading-container']}>
              <reactSvgs.loadingIcon />
            </div>
          )}
        </div>
      </div>
      {apiLoading && (
        <div className={styles['apiloading-container']}>
          <reactSvgs.loadingIcon width='100px'/>
        </div>
      )}
    </div>
  );
}

export default Overview;
