import React, { useRef } from "react";
import { motion } from "framer-motion";
import { animation1 } from "./ContectAnimation";
import { Link, useNavigate } from "react-router-dom";
import * as svgs from '../../../assets/svgs';
import * as images from '../../../assets/images';
import * as gifs from '../../../assets/gifs';
import * as reactSvgs from '../../../assets/reactSvgs';

import styles from '../../../styles/LandingPage.module.css';
import { general } from "../../../helpers/General";

const Footer = ({collectEmailFunction}) => {
    const emailRef = useRef("")
    const navigate = useNavigate()
    let userData = JSON.parse(localStorage.getItem("user"))
    
    return (
        <div className="pb-[60px] lg:pb-20 w-full">
            <div className="px-0 md:px-[calc((100vw-1100px)/2)]">
                <div
                    className="px-6 md:px-[110px] bg-white py-[60px] m-auto rounded-3xl flex flex-col items-center"
                >
                    <motion.p
                        className="text-[36px] lg:text-[54px] text-black font-semibold text-center m-auto w-full lg:w-4/5"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        Ready to Grow
                    </motion.p>
                    <motion.p
                        className="text-xl mt-8 text-home-1 w-full lg:w-2/3 m-auto text-center"
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        Are you ready to take your idea to the next level? Integrate <span className="font-bold text-home-7">Viral Waitlist</span> on your website in just <span className="font-bold text-home-7">2 minutes.</span>
                    </motion.p>
                    <motion.button
                        className="px-7 py-3 w-[145px] mt-10 text-home-4 bg-black rounded-md m-auto"
                        onClick={() => {!!userData?.userId ? navigate("/create-waitlist") : navigate("login")}}
                        variants={animation1}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        Get Started
                    </motion.button>
                </div>
            </div>
            <div className=" text-home-1 gap-2 mt-12 lg:gap-6 flex items-center justify-center flex-col lg:flex-row">
                {/* <Link to="ctontact">Contact</Link> */}
                <Link to="https://docs.google.com/document/d/1Clfka-lbezGjj5L4YFlQeSgJGNGapuT6ujGHarAqAZ0/edit?usp=sharing" target="_blank">Privacy & Cookie Policy</Link>
                <Link to="https://docs.google.com/document/d/1ZuQfXVzFZUTtjq_7aMARDIi3akoLDCNXfHBuzU7TrM4/edit?usp=sharing" target="_blank">Terms & Conditions</Link>
            </div>
        </div>
    );
};

export default Footer;
